import { GetDataColumns } from "../../smh/config/TableConfig";
import { PaginationRequest, MDMResult } from "../ProjectModels";
import { SmhApi } from "../SmhApi";
import { MDMHistoryFilter, MDMTableDetailsFilter, MDMTableFilter, MDMTableList } from "./Modals";
import { useMDMTableDetailsStore, useMDMTableStore, useViewHistoryMDMStore } from "./Store";

/**
 * 
 * @param error 
 * @param store 
 * @param headerKeyName 
 * @param listKeyName 
 */
const apiCatch = (error:any, store:any) => {
    console.error("API Error:", error);
       store.setState({
           list: [],
            paginationResult: { currentPage: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 },
            headers: [],
        });
}

/**
 * @function mdm table service for api call and manage the state for grid.
 */
export class MDMTableService {
    public static async getMDMTableList(pagination: PaginationRequest, filter: MDMTableFilter) {
        useMDMTableStore.setState({isLoading: true});
         SmhApi.getMDMTableList(pagination, filter, (result: MDMResult<MDMTableList>) => {
            if(result.code === 200 || result.code === 201) {
                const { data : {items, pagination, dbMetadata} }:any = result;
                useMDMTableStore.setState({list: items});
                useMDMTableStore.setState({paginationResult: pagination});
                useMDMTableStore.setState({headers: GetDataColumns(dbMetadata, 'masterDataView')});
            } else {
                throw new Error("Invalid response from the Server");
            }
        }).catch(error =>  apiCatch(error, useMDMTableStore)).finally(() => {
            useMDMTableStore.setState({isLoading: false});
          });
    }
}

/**
 * @table - @dynamic table details service.
 */
export class MDMTableDeatilsService {
    /**
     * 
     * @param pagination 
     * @param filter 
     * @returns it is going to update state
     * @purpose this method is required for get mdm detail tables records
     */
    public static async getMDMDetailsTableRecords(pagination: PaginationRequest, filter: MDMTableDetailsFilter) {
        useMDMTableDetailsStore.setState({isLoading: true});
         SmhApi.getMDMDetailsRecords(pagination, filter).then((response: MDMResult<any>) => {
           const { data: result } :any  = response;
            if(result?.code === 200 || result?.code === 201) {
                const { data : { items, pagination, dbMetadata } }:any = result;
                useMDMTableDetailsStore.setState({list:items || []});
                useMDMTableDetailsStore.setState({paginationResult: pagination});
                useMDMTableDetailsStore.setState({headers:GetDataColumns(dbMetadata, 'mdmDetailsViews')});
            } else {
                throw new Error("Invalid response from the Server");
            }
         }).catch(error => apiCatch(error, useMDMTableDetailsStore)).finally(() => useMDMTableDetailsStore.setState({isLoading: false}));
    }
    
    /**
     * 
     * @param systemId 
     * @returns 
     * @purpose for delete mdm data based on system id
     */
    public static async deleteMDMData(systemId: string | number, tableName: string | undefined, commitReason: string, isDeleteAll:boolean): Promise<any> {
        return SmhApi.deleteMDMData(systemId, tableName, commitReason, isDeleteAll)
    }

    /**
     * 
     * @param systemId 
     * @returns 
     * @purpose for delete mdm data based on system id
     */
        public static async uploadMDMData(tableName: Partial<string | undefined>, file: File[]): Promise<any> {
            return SmhApi.uploadMDMData(tableName, file)
        }

    /**
     * 
     * @param systemId 
     * @returns 
     * @purpose for delete mdm data based on system id
     */
      public static async saveMdmData(tableName: Partial<string | undefined>, savePayload: any, primarykey: string, isEdit?: boolean): Promise<any> {
        if(isEdit) {
            return SmhApi.editMdmData(tableName,savePayload?.[primarykey], savePayload)
        } else {
            delete savePayload?.[primarykey];
        }
        return SmhApi.createMdmData(tableName, savePayload)
       }
}

/**
 * @class MDM View History
 * @purpose this is get all MDM history and commit's records
 */
 export class MDMViewHistory {
    public static async getHistory(pagination: PaginationRequest, filter: MDMHistoryFilter) {
        useViewHistoryMDMStore.setState({isLoading: true});
         SmhApi.getHistory(pagination, filter, (result: MDMResult<MDMTableList>) => {
            if(result.code === 200 || result.code === 201) {
                const { data : {items, pagination, dbMetadata} }:any = result;
                useViewHistoryMDMStore.setState({list: items});
                useViewHistoryMDMStore.setState({paginationResult: pagination});
                useViewHistoryMDMStore.setState({headers: GetDataColumns(dbMetadata, 'mdmTableHistoryViews')});
            } else {
                throw new Error("Invalid response from the Server");
            }
        }).catch(error => apiCatch(error, useViewHistoryMDMStore)).finally(() => useViewHistoryMDMStore.setState({isLoading: false}));
    }
 }
