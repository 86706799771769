import React, { useState, useRef, useEffect, useCallback } from "react";
import { Checkbox, Select, SelectOption } from "@patternfly/react-core";
import { createPortal } from "react-dom";
import { CaretDownIcon } from "@patternfly/react-icons";

interface DataTableCheckboxDropDownProps {
  itemPerPage: number;
  itemCount: number;
  onSelect?: (selection: number) => void;
  checkedAll?: boolean;
}

const CheckboxDropdown: React.FC<DataTableCheckboxDropDownProps> = ({
  itemPerPage,
  itemCount,
  onSelect,
  checkedAll
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false || checkedAll);
  const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({});
  const checkboxRef = useRef<HTMLDivElement>(null);
  const options = [
    { value: 0, label: "Select none (0 items)" },
    { value: itemPerPage, label: `Select page (${itemPerPage} items)` },
    { value: 'all', label: `Select All (${itemCount} items)` },
  ];

  useEffect(()=>{
    setSelectAll(checkedAll);
  },[checkedAll])
  
  const calculateDropdownPosition = useCallback(() => {
    if (isOpen && checkboxRef.current) {
      const rect = checkboxRef.current.getBoundingClientRect();
      setDropdownStyle({
        position: "fixed",
        top: rect.bottom + window.scrollY + 10,
        left: rect.left + window.scrollX - 20,
        zIndex: 9999,
        background: "white",
        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        width: "200px",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      calculateDropdownPosition();
      window.addEventListener("scroll", calculateDropdownPosition);
      window.addEventListener("resize", calculateDropdownPosition);
    } else {
      window.removeEventListener("scroll", calculateDropdownPosition);
      window.removeEventListener("resize", calculateDropdownPosition);
    }
    return () => {
      window.removeEventListener("scroll", calculateDropdownPosition);
      window.removeEventListener("resize", calculateDropdownPosition);
    };
  }, [isOpen, calculateDropdownPosition]);

  const onChecked:any = useCallback(
    (_event: React.MouseEvent | React.ChangeEvent, selection: number) => {
      setSelectAll(selection > 0);
      setIsOpen(false);
      onSelect?.(selection);
    },
    [onSelect]
  );

  return (
    <>
      <div ref={checkboxRef} style={{ display: "inline-block" }}>
        <Checkbox
          id="select-all-checkbox"
          isChecked={selectAll || checkedAll}
          onChange={() => setIsOpen(!isOpen)}
          style={{ transform: "scale(1.2)" }}
        />
        <CaretDownIcon
          onClick={() => setIsOpen(!isOpen)}
          style={{ marginLeft: "7px", height: "18px", cursor: "pointer" }}
        />
      </div>
      {isOpen &&
        createPortal(
          <div style={dropdownStyle}>
            <Select isOpen onSelect={onChecked} toggle={(toggleRef) => <span ref={toggleRef} />}>
              {options.map((option) => (
                <SelectOption key={option.value} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </Select>
          </div>,
          document.body
        )}
    </>
  );
};

export default CheckboxDropdown;
