/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {SsoApi} from "./SsoApi";
import {EventStreamContentType, fetchEventSource} from "@microsoft/fetch-event-source";
import {ProjectEventBus} from "./ProjectEventBus";
import {KaravanApi} from "./KaravanApi";

export class LogWatchApi {

    static async fetchData(type: 'container' | 'build' | 'none', podName: string, controller: AbortController) {
        const fetchData = async () => {
            const headers: Record<string, string> = { Accept: "text/event-stream" };
            let ready = false;

            // @ts-ignore
            if (KaravanApi.authType === 'oidc' && SsoApi.keycloak && SsoApi.keycloak.token?.length > 0) {
                if (SsoApi.keycloak.isTokenExpired()) {
                    await SsoApi.keycloak.updateToken(10); // Refresh token if expired
                }
                headers["Authorization"] = `Bearer ${SsoApi.keycloak?.token}`;
                ready = true;
            } else if (KaravanApi.authType === 'basic' && KaravanApi.basicToken?.length > 0) {
                headers["Authorization"] = `Basic ${KaravanApi.basicToken}`;
                ready = true;
            } else {
                ready = KaravanApi.authType === 'public';
            }

            if (ready) {
                await fetchEventSource('/ui/logwatch/' + type + '/' + podName, {
                    method: "GET",
                    headers: headers,
                    signal: controller.signal,
                    async onopen(response) {
                        if (response.status === 401 && KaravanApi.authType === 'oidc' && SsoApi.keycloak) {
                            console.log("Unauthorized: Retrying with refreshed token");
                            await SsoApi.keycloak.updateToken(10); // Refresh token
                            return fetchData(); // Retry with new token
                        } else if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
                            return; // Stream established
                        }
                        console.error("Error in response: ", response);
                    },
                    onmessage(event) {
                        ProjectEventBus.sendLog('add', event.data);
                    },
                    onclose() {
                        console.log("Connection closed by the server");
                    },
                    onerror(err) {
                        console.error("Stream error occurred: ", err);
                    },
                });
            }
        };
        return fetchData();
    }
}
