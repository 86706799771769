export const OperationOptions: Record<string, { label: string; value: string, content: string }[]> = {
    number: [
      { label: "Equals", value: "equals", content: 'Equals' },
      { label: "Not Equals", value: "not equals", content: 'Not Equals' },
      { label: "Less Than", value: "less than", content: 'Less Than' },
      { label: "Less Than Equal To", value: "less than equal to", content: 'Less Than Equal To' },
      { label: "Greater Than", value: "greater than", content: 'Greater Than' },
      { label: "Greater Than Equal To", value: "greater than equal to", content: 'Greater Than Equal To' },
    ],
    date: [
      { label: "Equals", value: "equals", content: 'Equals' },
      { label: "Not Equals", value: "not equals", content: 'Not Equals' },
      { label: "Less Than", value: "less than", content: 'Less Than' },
      { label: "Less Than Equal To", value: "less than equal to", content: 'Less Than Equal To' },
      { label: "Greater Than", value: "greater than", content: 'Greater Than' },
      { label: "Greater Than Equal To", value: "greater than equal to", content: 'Greater Than Equal To' },
    ],
    text: [
      { label: "Equals", value: "equals", content: 'Equals' },
      { label: "Like", value: "like", content: 'Like' },
    ],
};

export const FilterOptionDisplay: Record<string, string> = {
    'equals': "is",
    "not equals": "is not",
    'like': "is like",
    "greater than": "is greater than",
    "greater than equal": "is greater than or equal to",
    "less than": "is less than",
    "less than equal to": "is less than or equal to",
};