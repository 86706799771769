export class MDMTableList {
  table_name: string = "";
  purpose: string = "";
  usage: string = "";
  type: string = "";
  source: string = "";

  public constructor(
    table_name: string,
    purpose: string,
    usage: string,
    type: string,
    source: string
  ) {
    this.table_name = table_name;
    this.purpose = purpose;
    this.usage = usage;
    this.type = type;
    this.source = source;
  }
}

export class MDMTableFilter {
  static DEFAULT_SEARCHTERM = undefined;
  searchTerm: string | undefined;

  public constructor(searchTerm : string | undefined = '') {
    this.searchTerm = searchTerm;
  }
  public static default(): MDMTableFilter {
    return new MDMTableFilter(MDMTableFilter.DEFAULT_SEARCHTERM);
  }
}

/**
 * MDM Custom Header
 */
export interface MDMHeaders {
  header: string;
  key: string;
  component?: (props:GenericType)=> any;
  link?: string;
}
/**
 * @generic data type
 */
export interface GenericType {
  [key: string]: any;       
}

/**
 * @generic MDMTableDetails type
 */
export interface MDMTableDetails {
  isChecked: boolean; // For multiple select
  [key: string]: any;       
}


/**
 * @purpose it is filter class for mdm details table.
 */
export class MDMTableDetailsFilter {
  static DEFAULT_TABLE_NAME:string;
  static DEFAULT_SEARCHTERM = undefined;
  static FILTER = undefined;
  searchTerm: Partial<string | undefined>;
  tableName: Partial<string | undefined>;
  filter?: GenericType[];

  public constructor(tableName: string, searchTerm : string | undefined = '', filter?: GenericType[]) {
    this.tableName = tableName;
    this.searchTerm = searchTerm;
    this.filter = filter;
  }
  public static default(): MDMTableDetailsFilter {
    return new MDMTableDetailsFilter(MDMTableDetailsFilter.DEFAULT_TABLE_NAME, MDMTableDetailsFilter.DEFAULT_SEARCHTERM, MDMTableDetailsFilter.FILTER);
  }
}

/**
 * @type mdm history list  as generic
 */
export interface MDMHistoryList {
  isChecked: boolean; // For multiple select
  [key: string]: any;       
}

/**
 * @filter - mdm history class
 */
export class MDMHistoryFilter {
  static DEFAULT_SEARCHTERM = undefined;
  static DEFAULT_TABLE_NAME:string;
  searchTerm: string | undefined;
  tableName: Partial<string | undefined>;
  public constructor(tableName: string, searchTerm : string | undefined = '') {
    this.searchTerm = searchTerm;
    this.tableName = tableName;
  }

  public static default(): MDMHistoryFilter {
    return new MDMHistoryFilter(MDMHistoryFilter.DEFAULT_TABLE_NAME, MDMHistoryFilter.DEFAULT_SEARCHTERM);
  }
}