import React, { useEffect, useState } from 'react';
import {
  PageSection,
  Text,
  TextContent,
  TextVariants,
  Title,
  Grid,
  GridItem,
  Card,
  CardBody,
  Button,
  Tooltip,
  Bullseye,
  Spinner,
} from '@patternfly/react-core';
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import './commitHistory.css';
import { useParams } from 'react-router-dom';
import MdmTableToolbar from "../Toolbar";
import { SmhApi } from '../../../api/SmhApi';
import { UserProfile } from '../../shared/data-table/DataTableActions';
import { EventBus } from '../../../designer/utils/EventBus';
export const CommitHistory: React.FC = () => {
  const { tableName, commitRecordId }: any = useParams();
  const [commitHistoryData, setCommitHistoryData] = useState<any>({})
  const [isLoading, setLoading] = useState<boolean>(true);
  const getHistory = async () => {
    try {
      const { data: { data } } = await SmhApi.getCommitViewHistory(tableName, commitRecordId);
      if (data) {
        setCommitHistoryData(data);
      }
    } catch(e:any){
      EventBus.sendAlert("Error", e?.message || "Ops!!, Something went wrong.", "danger");
      setCommitHistoryData({});
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getHistory();
  }, [])

  const onToolBarAction = (type: any) => {
    getHistory();
  }

  const isPositiveCol = (row: any, column?: any) => {
    if (column && column.key) {
      if (!column.primary && row?.[column.key]?.startsWith('+')) return 'col-positive-cls';
      if (!column.primary && row?.[column.key]?.startsWith('-')) return 'col-negative-cls';
      const isPrimarykey = commitHistoryData?.dbMetadata?.find((row: any) => row.primary)?.key;
      if (isPrimarykey) {
        const isAllPostive = commitHistoryData?.items.every((row: any) => row?.[isPrimarykey]?.startsWith('+'));
        if (!column.primary && isAllPostive) return 'col-positive-cls';
        const isAllNegative = commitHistoryData?.items.every((row: any) => row?.[isPrimarykey]?.startsWith('-'));
        if (!column.primary && isAllNegative) return 'col-negative-cls';
      }
      return ''
    }
    const objValues = Object.values(row);
    const isPositive = objValues.some((val: any) => {
      if (val.startsWith('+')) {
        return true;
      }
      return false;
    })
    return isPositive;
  }

  const getHeaderKey = (key: string) => {
    const splitWords = key.split(/(?=[A-Z])|[_-]/);
    let header = splitWords
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return header;
  }

  const PlusMinusIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width={'13px'}
      height={'13px'}>
      <path d="M224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112L48 144c-17.7 0-32 14.3-32 32s14.3 32 32 32l112 0 0 112c0 17.7 14.3 32 32 32s32-14.3 32-32l0-112 112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-112 0 0-112zM0 480c0 17.7 14.3 32 32 32l320 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 448c-17.7 0-32 14.3-32 32z" /></svg>
  );

  const getWidth = (widthFlag: string) => {
    const value = commitHistoryData?.commitDetails?.[1]?.[widthFlag] || 0;
    const totalAmount = commitHistoryData?.commitDetails?.[1]?.totalCount || 0;
    const percentage = (value / totalAmount) * 100;
    return percentage + "%";
  }

  return (
    <>
      <MdmTableToolbar
        titleText={`View History`}
        toolBarContent={{ isRefresh: false }}
        onToolBarAction={onToolBarAction}
        breadCrumbItems={[{ title: 'Master Data Management', to: '/mdm' }, { title: tableName, to: `/mdm/${tableName}` }, { title: 'View History', to: `/mdm/${tableName}/viewHistory` }, { title: `#${commitRecordId}` }]}

      />
      {
        isLoading && (<PageSection style={{ paddingBottom: '13px' }}>
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        </PageSection>)
      }
      {
        !isLoading && (<>
          <PageSection style={{ paddingBottom: '13px' }}>
            <PageSection variant='light' style={{ paddingTop: '15px', paddingBottom: '15px' }}>
              <TextContent>
                <Title headingLevel="h2" className='commit-long-text'>{commitHistoryData.commitDetails?.[0].commitReason}</Title>
                <>
                  <span style={{ display: 'flex', gap: '8px' }} className='commit-user-info'> <UserProfile userName={commitHistoryData.commitDetails?.[0].userId} value={commitHistoryData.commitDetails?.[0].userId} className="custom-author" /> <span>committed {commitHistoryData.commitDetails?.[0].lastCommitTime} ago</span></span>
                </>
              </TextContent>
            </PageSection>

          </PageSection>
          <PageSection style={{ paddingTop: '0px', paddingBottom: '18px' }}>
            <Grid hasGutter>
              <GridItem span={12}>
                <Card>
                  <CardBody style={{ padding: '10px' }}>
                    <Text component={TextVariants.small}>
                      < ><Button variant="link" icon={<PlusMinusIcon />} style={{ paddingRight: '0px' }} /> <span style={{ fontSize: '16px' }}> <span style={{ color: '#F9A725' }}>1 changed entry</span> with <span style={{ color: 'green' }}> {commitHistoryData.commitDetails?.[1].additionCount} additions </span>  and <span style={{ color: 'red' }}>{commitHistoryData.commitDetails?.[1].deletionCount} deletions</span> </span></>

                    </Text>
                  </CardBody>
                </Card>
              </GridItem>
            </Grid>
          </PageSection>
          <PageSection className='commit-table-list'>
            <div
              style={{
                maxHeight: "60vh", // Set table container height
                overflowY: "auto",
                marginTop: '-30px',
                width: "100%", overflowX: "auto"
              }}
            >
              <Table className="pf-c-table pf-m-grid-md" aria-label="Table Data" gridBreakPoint="">
                <Thead>
                  <Tr colSpan={12}>
                    <Td colSpan={12} style={{ paddingTop: '9px', paddingBottom: '6px' }}>
                      <span className='count-span'> {commitHistoryData?.commitDetails?.[1]?.totalCount || 0} </span>
                      <Tooltip content={`${commitHistoryData?.commitDetails?.[1]?.totalCount} changes: ${commitHistoryData?.commitDetails?.[1]?.additionCount} additions  ${commitHistoryData?.commitDetails?.[1]?.deletionCount} deletions`} position={"top"}>
                        <div className='percante-elements'>
                          <div className='delete-icons' title='deleted ' style={{ width: getWidth('deletionCount') }}>&nbsp;</div><div className='insert-icons' style={{ width: getWidth('additionCount') }}>&nbsp;
                          </div>
                        </div>
                      </Tooltip>
                      <span className='table-elements'> <Text component={TextVariants.p} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '500' }}>
                        {tableName}
                      </Text> </span>
                    </Td>
                  </Tr>
                  <Tr>
                    <Th key={'constant-01'}></Th>
                    {commitHistoryData?.dbMetadata?.map((col: any, index: number) => (
                      <Th key={index}>{getHeaderKey(col.key)}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {commitHistoryData?.items?.map((row: any, rowIndex: any) => (
                    <Tr key={rowIndex}>
                      <Td style={{ paddingTop: '15px', paddingBottom: '15px' }} key={`count-${rowIndex}`}>{isPositiveCol(row) ? '+' : '-'}</Td>
                      {commitHistoryData?.dbMetadata?.map((cell: any, cellIndex: number) => (
                        <Td key={cellIndex} style={{ paddingTop: '10px', paddingBottom: '10px' }} className={`${isPositiveCol(row, cell)}`}>
                          {row?.[cell.key].replace(/^[+-]/, '')}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                  <Tr style={{ height: '50px' }}></Tr>
                </Tbody>
              </Table>
            </div>
          </PageSection>
        </>)
      }
    </>
  );
};
