import React from "react";
import { Breadcrumb, BreadcrumbItem } from "@patternfly/react-core";
import { Link } from "react-router-dom";
import './commonBreadcrumb.css'
interface BreadcrumbProps {
  items: { title: string; to?: string }[]; // Array of breadcrumb items
}

const CommonBreadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <Breadcrumb className="custom-breadcrumb"> 
      {items.map((item, index) => (
        <BreadcrumbItem key={index} isActive={index === items.length - 1} style={{margin: 0, padding: 0}}>
          {item.to ? <Link to={item.to}>{item.title}</Link> : item.title}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default CommonBreadcrumb;
