import React from "react";
import {RestBasicAuthenticationAddonToggleButton} from "../project/addons/RestBasicAuthenticationAddonToggleButton";
import {CustomExceptionLoggerAddonToggleButton} from "../project/addons/CustomExceptionLoggerAddonToggleButton";
import {NotImplementedAddonToggleButton} from "../project/addons/NotImplementedAddonToggleButton";
import {EncryptionToggleButton} from "../project/addons/EncryptionToggleButton";

export enum ProjectAddOnType {
    REST_BASIC_AUTHENTICATION = 'REST_BASIC_AUTHENTICATION',
    CUSTOM_EXCEPTION_LOGGER = 'CUSTOM_EXCEPTION_LOGGER',
    ENCRYPT_SECRET = 'ENCRYPT_SECRET'
}

export class ProjectAddOn {
    type: string = '';
    name: string = '';
    description: string = '';
    fileNames: string[] = [];
    toggleButton: React.FunctionComponent = NotImplementedAddonToggleButton;


    constructor(type: string, name: string, description: string, fileNames: string[], toggleButton: React.FunctionComponent) {
        this.type = type;
        this.name = name;
        this.description = description;
        this.fileNames = fileNames;
        this.toggleButton = toggleButton;
    }
}

export const ProjectAddOns: ProjectAddOn[] = [
    new ProjectAddOn(
        ProjectAddOnType.REST_BASIC_AUTHENTICATION,
        "REST Basic Authentication",
        "Add on enabling REST Basic Authentication support. Credentials should be added in the 'basic_auth_credentials.conf' file",
        ["basic_auth_credentials.conf" , "RestBasicAuthentication.java"],
        RestBasicAuthenticationAddonToggleButton
    ),
    new ProjectAddOn(
        ProjectAddOnType.CUSTOM_EXCEPTION_LOGGER,
        "Custom Exception Logger",
        "Add on enabling Custom exception logger support. Prefix log fields that are present in body needs to be defined in 'custom_exception_logger.conf' file.",
        ["CustomExceptionLogger.java", "custom_exception_logger.conf"],
        CustomExceptionLoggerAddonToggleButton
    ),
    new ProjectAddOn(
        ProjectAddOnType.ENCRYPT_SECRET,
        "Encrypting Sensitive Information",
        "Encrypting Sensitive Information and get encrypted value",
        ["encrypt_secret.camel.yaml"],
        EncryptionToggleButton
    )
];
