import { Button, Checkbox, Tooltip } from "@patternfly/react-core";
import {  PencilAltIcon, BellIcon,  LockIcon, TrashIcon } from "@patternfly/react-icons";
import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const LinkComponent = memo(({ link, value }: any) => {
  const navigate = useNavigate();
  const params: any =  useParams();
  let url = `${link}/${value}`;
  if (link === '/elc') {
    url = `/projects${link}/${params?.projectId}/details/${value}`;
  } else if(link === '/viewHistory') {
     url = `/mdm/${params.tableName}/viewHistory/${value}`;
  }
  return (
    <Button
      style={{ padding: "6px" }}
      variant={"link"}
      onClick={(e) => navigate(url)}
    >
      {value}
    </Button>
  );
});

export const ActionComponent = memo((props: any) => {
  const elements: any = [];
  props?.actionsList.forEach((rowEl: any, index: number) => {
    if (rowEl.key === 'isEditDisplay') {
      elements.push(
        <Tooltip key={index} content="Edit" position="top">
          <Button
            id="edit-mdm-data"
            key={index}
            variant="plain"
            onClick={() => props?.onActionClick("edit", props)}
          >
            <PencilAltIcon />
          </Button>
        </Tooltip>
      );
    }

    if (rowEl.key === 'isNotifyDisplay') {
      elements.push(
        <Tooltip key={index} content="Notification" position="top">
          <Button
            id="edit-mdm-data"
            key={0}
            variant="plain"
            onClick={() => props?.onActionClick("edit", props)}
          >
            <BellIcon
              style={{ fontSize: '13px' }}
              key={5}
              onChange={() => props?.onActionClick("notification", props)}
            />
          </Button>

        </Tooltip>

      );
    }

    if (rowEl.key === 'isDeleteDisplay') {
      elements.push(
        <Tooltip key={index} content="Delete" position="top">
          <Button
            id="delete-mdm-data"
            title="Delete"
            variant="plain"
            onClick={() => props?.onActionClick("delete", props)}
          >
            <TrashIcon title="Delete" />
          </Button>
        </Tooltip>
      );
    }
    if (rowEl.key === 'swapIcons') {
      elements.push(
        <Tooltip key={index} content="Reassign" position="top">
                <span style={{textAlign: 'center', marginTop: '5px', color: `var(--pf-v5-c-button--m-plain--Color)`}}>{ ReAssign() }</span> 
        </Tooltip>
      );
    }

    if (rowEl.key === 'isCheckBoxDispaly') {
      elements.push(
        <Checkbox
          key={index}
          isChecked={props?.value?.isChecked}
          onChange={() => props?.onActionClick("checkbox", props)}
          id={`checkbox-${props.value.systemId}`}
        />
      );
    }
    return rowEl
  });


  return <div style={{ display: 'flex', position: 'sticky', right: 0 }}> {elements} </div>;
});


export const UserProfile = ({ imageUrl='', userName='',  ...rest }: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }} className="user-profile">
      <img
        src={`${imageUrl || "https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659652_640.png"}`}
        alt={`${userName || "John Doe"}`}
        style={{
          width: "15px",
          height: "15px",
          borderRadius: "8px",
          objectFit: "cover",
        }}
      />
      <span style={{ fontSize: "12px", fontWeight: "bold" }} className="custom-username">{rest.value || "John Doe"}</span>
    </div>
  );
};

export const LockButton: React.FC = (props:any) => {
  return (
    <Button variant="primary" iconPosition="right" icon={<LockIcon />} className="lock-button-table-elements" onClick={()=>props.onActionClick(props)}>
      {props.value}
    </Button>
  );
};

export const PriorityElements = (props:any) => {
  if(props.value?.toLocaleLowerCase() === 'critical') {
    return <div className='critical-priority'>Critical</div>;
  }

  if(props.value) {
    return <>{props.value}</>;
  }
  return <>---</>
}


export const ReAssign = (color='#6a6e73') => {
  return (<svg width="12" height="12" viewBox="0 0 16 16"  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7803 3.91055L12.091 1.2212C11.6185 0.748724 10.8106 1.08335 10.8106 1.75156V3.19086H1.375C1.16791 3.19086 1 3.35876 1 3.56586V5.31585C1 5.52294 1.16791 5.69085 1.375 5.69085H10.8106V7.13018C10.8106 7.79839 11.6185 8.13301 12.091 7.66054L14.7803 4.97119C15.0732 4.67828 15.0732 4.20343 14.7803 3.91055Z" fill={`${color}`}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.2197 11.9713L3.90902 14.6606C4.3815 15.1331 5.18935 14.7985 5.18935 14.1303V12.691H14.625C14.8321 12.691 15 12.5231 15 12.316V10.566C15 10.3589 14.8321 10.191 14.625 10.191H5.18935V8.75166C5.18935 8.08345 4.38153 7.74882 3.90902 8.22129L1.2197 10.9106C0.926791 11.2036 0.926791 11.6784 1.2197 11.9713Z" fill={`${color}`}/>
  </svg>)
} 

export const RcaButton = (props:any) => {
  if(props.value !=='') {
    return <Button variant="link">View RCA   &nbsp; {BoxArrowIcon('#0066CC')} </Button>
  }
  return <>---</>
}

export const BoxArrowIcon = (color='#6a6e73') => {
  return <svg  width="16" height="16"   viewBox="0 0 16 16">
  <path  fill={`${color}`}  fill-rule="evenodd"  d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
  <path  fill={`${color}`}  fill-rule="evenodd"  d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
</svg>
}


export const CheckboxElement = ({index, onActionClick, ...rest}:any) =>{
  const { isChecked } = rest?.value || false;
  return (<Checkbox
   key={index}
  isChecked={isChecked}
  onChange={(event) => onActionClick("checkbox", rest, event)}
  id={`checkbox-${index}`}
  style={{transform: 'scale(1.2)'}}
/>);
}