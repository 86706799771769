import React, {useEffect, useState} from 'react';
import {
    Button,
    Modal,
    ModalVariant,
    Text,
    TextContent,
    TextVariants,
    Tooltip
} from '@patternfly/react-core';
import {useFilesStore, useProjectStore} from "../../api/ProjectStore";
import {shallow} from "zustand/shallow";
import {ProjectAddOnService} from "../../api/ProjectAddOnService";
import {ProjectAddOnType} from "../../api/SmhProjectModels";
import {EventBus} from "../../designer/utils/EventBus";

export const EncryptionToggleButton: React.FunctionComponent = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [project] = useProjectStore((state) => [state.project], shallow )
    const [files] = useFilesStore((s) => [s.files], shallow);
    const [isEncyptionEnabled, setIsEncyptionEnabled] = useState(false);

    const encryptionFileNames:any[] = ['encrypt_secret.camel.yaml']

    const handleModalToggle = (_event: KeyboardEvent | React.MouseEvent) => {
        setIsModalOpen(!isModalOpen);
    };

    const calculateIsEncryptionEnabled = () => {
        return files.filter(f => encryptionFileNames.includes(f.name)).length === 1;
    }

    const getModalTitle = () => {
        return isEncyptionEnabled ? "Warning" : "Info";
    }

    const getModalTitleIconVariant = () => {
        return isEncyptionEnabled ? "danger" : "info";
    }

    useEffect(() => {
        setIsEncyptionEnabled(calculateIsEncryptionEnabled())
    }, []);

    const handleAddOnToggleConfirmed = (_event: KeyboardEvent | React.MouseEvent) => {
        if(!isEncyptionEnabled) {
            ProjectAddOnService.enableAddOn(project.projectId, ProjectAddOnType.ENCRYPT_SECRET);
            EventBus.sendAlert( "Success", "Encryption add-on successfully enabled", "success");

            setIsEncyptionEnabled(true);
        } else {
            ProjectAddOnService.disableAddOn(project.projectId, ProjectAddOnType.ENCRYPT_SECRET);
            EventBus.sendAlert( "Success", "Encryption add-on successfully disabled", "success");

            setIsEncyptionEnabled(false);
        }

        setIsModalOpen(!isModalOpen);
    };

    return (
        <React.Fragment>
            {isEncyptionEnabled &&
                <Tooltip content={"Disable Encryption Add-On"} position={"bottom"}>
                    <Button variant="danger" onClick={handleModalToggle} >
                        Disable
                    </Button>
                </Tooltip>
            }
            {!isEncyptionEnabled &&
                <Tooltip content={"Enable Encryption Add-On"} position={"bottom"}>
                    <Button variant="primary" onClick={handleModalToggle}>
                        Enable
                    </Button>
                </Tooltip>
            }
            <Modal
                variant={ModalVariant.medium}
                title={getModalTitle()}
                titleIconVariant={getModalTitleIconVariant()}
                isOpen={isModalOpen}
                aria-describedby="modal-title-icon-description"
                onClose={handleModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={handleAddOnToggleConfirmed}>
                        Confirm
                    </Button>,
                    <Button key="cancel" variant="link" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                ]}
            >
                {!isEncyptionEnabled &&
                    <TextContent>
                        <Text component={TextVariants.h3}>Are you sure you want to enable Encryptions Add-On?</Text>
                    </TextContent>
                }
                {isEncyptionEnabled &&
                    <TextContent>
                        <Text component={TextVariants.h3}>Are you sure you want to disable Encryptions Add-On?</Text>
                    </TextContent>
                }
            </Modal>
        </React.Fragment>
    );
};
