import {
  Breadcrumb,
  Button,
  PageSection,
  Text,
  TextContent,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
} from "@patternfly/react-core";
import { PlusIcon, UploadIcon, SyncIcon, HistoryIcon } from "@patternfly/react-icons";
import { MainToolbar } from "../../designer/MainToolbar";
import  { memo, useMemo } from "react";
import CommonBreadcrumb from "../shared/CommonBreadcrumb";

export default memo(({ titleText, onToolBarAction, toolBarContent, breadCrumbItems }: any) => {
  const elements: any[] = [];

  if (toolBarContent?.isRefresh) {
    elements.push(
      <ToolbarItem key={0} title="Refresh">
        <Button
          variant="link"
          icon={<SyncIcon />}
          onClick={(e) => onToolBarAction("refresh")}
          title="Refresh"
        />
      </ToolbarItem>
    );
  }

  if (toolBarContent?.isViewHistory) {
    elements.push(
      <ToolbarItem key={1}>
        <Button
          variant="secondary"
          icon={<HistoryIcon />}
          onClick={(_) => onToolBarAction("viewHistory")}
        >
          View History
        </Button>
      </ToolbarItem>
    );
  }

  if (toolBarContent?.isUpload) {
    elements.push(
      <ToolbarItem key={2}>
        <Button
          variant="secondary"
          icon={<UploadIcon />}
          onClick={(_) => onToolBarAction("upload")}
        >
          Upload
        </Button>
      </ToolbarItem>
    );
  }

  if (toolBarContent?.create) {
    elements.push(
      <ToolbarItem key={3}>
        <Button
          variant="primary"
          icon={<PlusIcon />}
          onClick={(_) => onToolBarAction("create")}
        >
          Create
        </Button>
      </ToolbarItem>
    );
  }
  const title = useMemo(
    () => (
      <TextContent key={3}>
        <Text component="h3" style={{marginBottom: '0px'}}>{titleText}</Text>{" "}
        {
          breadCrumbItems && breadCrumbItems.length > 0  && (<CommonBreadcrumb  items={[...breadCrumbItems]} />)
        }
       
      </TextContent>
    ),
    [titleText]
  );

  const toolsList = useMemo(
    () => (
      <Toolbar id="toolbar-group-types" className="toolbar-group-types" style={{minHeight: '66px'}}>
        <ToolbarContent>{elements}</ToolbarContent>
      </Toolbar>
    ),
    [titleText]
  );

  return (
    <PageSection className="tools-section" padding={{ default: "noPadding" }}>
      <MainToolbar title={title} tools={toolsList} />
    </PageSection>
  );
});
