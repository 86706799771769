import React, { useEffect } from "react";
import { PaginationRequest } from "../../../api/ProjectModels";
import { useViewHistoryMDMStore } from "../../../api/mdm/Store";
import { MDMHistoryFilter } from "../../../api/mdm/Modals";
import { MDMViewHistory } from "../../../api/mdm/Service";
import DataTable from "../../shared/data-table/DataTable";
import MdmTableToolbar from "../Toolbar";
import { useParams } from "react-router-dom";
interface TableParams {
  tableName: string;
}
export const ViewHistory = () => {
  const {
    paginationRequest,
    setPaginationRequest,
    paginationResult,
    activeFilter,
  } = useViewHistoryMDMStore();
  const { tableName }: Partial<TableParams> = useParams();
  const getLatestRecords = () => {
    const defaultFilter = MDMHistoryFilter.default();
    defaultFilter.tableName = tableName;
    useViewHistoryMDMStore.getState().setActiveFilter(defaultFilter);
    getTableData(PaginationRequest.default(), defaultFilter);
  }
  useEffect(() => {
    getLatestRecords();
  }, []);

  const getTableData = (
    pagination: PaginationRequest,
    filter: MDMHistoryFilter
  ) => {
    return MDMViewHistory.getHistory(pagination, filter);
  };

  const onSetPage = (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPage: number
  ) => {
    const newPaginationRequest = new PaginationRequest(
      newPage,
      paginationRequest.size
    );

    setPaginationRequest(newPaginationRequest);
    return getTableData(newPaginationRequest, activeFilter);
  };

  const onPerPageSelect = (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPerPage: number,
    newPage: number
  ) => {
    const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

    setPaginationRequest(newPaginationRequest);
    return getTableData(newPaginationRequest, activeFilter);
  };

  const onSearch = ({ target: { value } }: any): any => {
    const newPaginationRequest = new PaginationRequest(
      1,
      paginationRequest.size
    );
    setPaginationRequest(newPaginationRequest);
    const filter = MDMHistoryFilter.default();
    filter.searchTerm = value;
    filter.tableName = tableName;
    MDMViewHistory.getHistory(newPaginationRequest, filter);
    useViewHistoryMDMStore.getState().setActiveFilter(filter);
  };

  const onToolBarAction = (type: string) => {
    if (type === "refresh") {
      getLatestRecords();
    }
  };

  return (
    <>
      <MdmTableToolbar
        titleText={`View History`}
        toolBarContent={{ isRefresh: false }}
        onToolBarAction={onToolBarAction}
        breadCrumbItems={[{title: 'Master Data Management', to: '/mdm'}, {title: tableName, to: `/mdm/${tableName}`}, {title: 'View History'}]}
      />
      <DataTable
        headers={useViewHistoryMDMStore.getState().headers || []}
        itemCount={paginationResult?.totalItems}
        data={useViewHistoryMDMStore.getState().list || []}
        tableName="masterDataView"
        itemPerPage={paginationResult.itemsPerPage}
        pages={paginationRequest.page}
        onSetPage={onSetPage}
        onPerPageSelect={onPerPageSelect}
        onSearch={onSearch}
        isLoading={useViewHistoryMDMStore.getState().isLoading || false}
        isFilterDispaly={false}
      />
    </>
  );
};
