import { Button, Flex, FlexItem } from "@patternfly/react-core";
import { FilterOptionDisplay } from "./FilterOptions";

const SaveFilterText = (filters: any[], headers: any[] | undefined): any => {
  const getHeaderName = (keyName: string) => headers?.find((row: any) => row.key === keyName)?.header;
  return <>{filters
    ?.map((filter, index) => {
      const operatorText = FilterOptionDisplay[filter.operator.toLowerCase()] || filter.operator;
      const logicalOp = index > 0 ? ` ${filter.logicalOperator.toLowerCase()} ` : "";
      return <span>
        {index > 0 && (
          <span key={`logical-${index}`} className="logical-filters">
            {logicalOp}
          </span>
        )
        }
        <span key={`field-${index}`} className="field-filters">
          {getHeaderName(filter.field)}
        </span>
        <span key={`operator-${index}`} className="operator-filters">
          {operatorText}
        </span>
        <span key={`value-${index}`} className="value-filters">
          {filter.value}
        </span>
      </span>
    })
  }</>
};


export default ({ filters, headers, cb }: any) => {
  const clearFilters = () => {
    cb(null, 'clearFilters')
  }
  return (
    <Flex
      className="toolbar"
      direction={{ default: "row" }}
      style={{ marginLeft: '1.2%', marginRight: '1.2%', height: '3em', marginTop: '5px', marginBottom: '10px' }}
    >
      <FlexItem style={{ maxWidth: '85%', backgroundColor: '#E7F1FA', minHeight: '2.5em', padding: '8px' }}>{SaveFilterText(filters, headers)}</FlexItem>
      <FlexItem style={{ marginLeft: 'auto' }}><Button variant="link" onClick={clearFilters}> Clear Filter</Button></FlexItem>
    </Flex>
  )
} 