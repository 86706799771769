import React, { useState } from "react";
import {
  PageSection,
  TextContent,
  Text,
  Button,
  Flex,
  FlexItem,
  TextInput,
  PageSectionVariants,
  Spinner,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
} from "@patternfly/react-core";
import "../../projects/ProjectsPage.css";

import { MainToolbar } from "../../designer/MainToolbar";
import { SmhApi } from "../../api/SmhApi";
import { EventBus } from "../../designer/utils/EventBus";
import RefreshIcon from "@patternfly/react-icons/dist/esm/icons/sync-alt-icon";

export default () => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [encryptedValue, setEncryptedValue] = useState<string>("");
  const title = (
    <TextContent>
      <Text component="h2">Encrypt Secret</Text>
    </TextContent>
  );
  const onChange = ({ target: { value } }: any) => {
    setInputValue(value);
  };
  const handleEncrypt = () => {
    setLoading(true);
    SmhApi.encryptValue(inputValue, (res) => {
      if (res.status === 200) {
        setLoading(false);
        setEncryptedValue(res?.data);
      } else {
        EventBus.sendAlert(
          "Error",
          res?.data?.message || res?.message || "Ops!!, something wrong",
          "danger"
        );
        setLoading(false);
      }
    });
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(encryptedValue).then(() => {
      EventBus.sendAlert(
        "Success",
        "Successfully copied an encrypted text!!",
        "success"
      );
    });
  };

  const reEncrypt = () => {
    setInputValue("");
    setEncryptedValue("");
  };

  const getTools = () => (
    <Toolbar id="toolbar-group-types">
      <ToolbarContent>
        <ToolbarItem>
          <Button
            variant="link"
            icon={<RefreshIcon />}
            style={{ color: "white" }}
          />
        </ToolbarItem>
      </ToolbarContent>
    </Toolbar>
  );
  return (
    <PageSection className="projects-page" padding={{ default: "noPadding" }}>
      <PageSection className="tools-section" padding={{ default: "noPadding" }}>
        <MainToolbar title={title} tools={getTools()} />
      </PageSection>
      <PageSection isFilled className="project-section">
        <PageSection
          variant={PageSectionVariants.light}
          style={{
            border: "1px solid #d2d2d2",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          {encryptedValue === "" && (
            <Flex
              alignItems={{ default: "alignItemsCenter" }}
              spaceItems={{ default: "spaceItemsMd" }}
              className="pf-u-w-100"
            >
              <FlexItem
                className="pf-u-w-60 pf-u-w-100-on-sm"
                style={{ flex: "1 0 auto", width: "90%" }}
              >
                <TextInput
                  type="text"
                  id="pattern-input"
                  placeholder="Enter value for encryption.."
                  aria-label="Encryption input"
                  onChange={onChange}
                  autoFocus
                />
              </FlexItem>
              <FlexItem
                className="pf-u-w-auto"
                style={{ flex: "0 0 auto", width: "auto" }}
              >
                <Button variant="primary" onClick={handleEncrypt}>
                  Encrypt
                </Button>
              </FlexItem>
              {loading && (
                <FlexItem>
                  <Spinner size="lg" aria-label="Loading spinner" />
                </FlexItem>
              )}
            </Flex>
          )}

          {encryptedValue !== "" && (
            <Flex
              alignItems={{ default: "alignItemsCenter" }}
              spaceItems={{ default: "spaceItemsMd" }}
              className="pf-u-w-100"
            >
              <FlexItem
                style={{ flex: "1 0 auto", width: "80%" }}
                className="pf-u-w-60 pf-u-w-100-on-sm"
              >
                <TextInput
                  type="text"
                  id="pattern-input"
                  placeholder="Copy Encrypted value"
                  aria-label="Copy Encrypted value"
                  value={encryptedValue}
                  isDisabled={true}
                />
              </FlexItem>
              <FlexItem
                className="pf-u-w-auto"
                style={{ flex: "0 0 auto", width: "auto" }}
              >
                <Button variant="secondary" onClick={reEncrypt}>
                  Encrypt Again
                </Button>
              </FlexItem>
              <FlexItem
                className="pf-u-w-auto"
                style={{ flex: "0 0 auto", width: "auto" }}
              >
                <Button variant="primary" onClick={handleCopyToClipboard}>
                  Copy to Clipboard
                </Button>
              </FlexItem>
            </Flex>
          )}
        </PageSection>
      </PageSection>
    </PageSection>
  );
};
