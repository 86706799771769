import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Select,
  SelectOption,
  SelectList,
  MenuToggle,
  MenuToggleElement,
  TextInput
} from "@patternfly/react-core";
import { SmhApi } from "../../../api/SmhApi";
let pageNumber = 0;
let pageHighet = 200;
const debounce = (callback: (...args: any[]) => void, delay: number) => {
    let timer: NodeJS.Timeout;
  
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  };

const InfiniteScrollSearchDropdown= ({tableName, colKey, index, onSelect, selectedValue}:any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
    const [options, setOptions] = useState<any>({});
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setLoading] = useState(false);

    const debouncedSearch = useRef(
        debounce(async (searchTerm: string, colKey: string, index:number) => {
            pageNumber = 0;
            setLoading(true);
            setSearchValue(searchTerm);
            let { data }:any = await SmhApi.getColumnRecords(tableName,colKey, 0, searchTerm);
            if(data?.data?.items?.length){
                const setList  = [...new Set(data?.data?.items)];
                const items = setList.map((row:any) => {
                  return { label: row, value: row, content: row}
                });
                setOptions((prevOptions:any) => ({ ...prevOptions, [index]: [...items ]}));
                setLoading(false)
            } else {
                setOptions((prevOptions:any) => ({ ...prevOptions, [index]: []}));
                setLoading(false)
            }
        }, 1000) // Debounce for 1 second
      ).current;

  useEffect(()=>{
      const apiCall = async () =>{
        if(!colKey) return
          let { data }:any = await SmhApi.getColumnRecords(tableName,colKey, 0, '');
                setLoading(true)
                if(data?.data?.items?.length){
                   const setList  = [...new Set(data?.data?.items)];
                    const items = setList.map((row:any) => {
                      return { label: row, value: row, content: row}
                    });
                    setOptions((prevOptions:any) => ({ ...prevOptions, [index]: [...items ]}));
                    if(data?.data?.pagination.totalPages > 1) {
                        setHasMore(true);
                    } else {
                        setHasMore(false);
                    }
                }
                setLoading(false);
      }
      pageNumber = 0;
      apiCall();
  },[colKey])

  const selectOptionChange = (e:any,value: any):void => {
    onSelect && onSelect(value, colKey,index);
    setIsOpen(false);
  }

  const filteredOptions = options?.[index] || [];
  const fetchMoreOptions = () =>{
    const apiCall = async () =>{
      if(!colKey) return;
      
      pageNumber = pageNumber+1;
        let { data }:any = await SmhApi.getColumnRecords(tableName,colKey, pageNumber, searchValue);
              if(data?.data?.items?.length){
                  const setList  = [...new Set(data?.data?.items)];
                  const items = setList.map((row:any) => {
                    return { label: row, value: row, content: row}
                  });

                  const totalData:any[] = [...options?.[index],...items ];
                  const uniqueData = [...new Set(totalData.map((row:any) => row.value))].map(row =>{
                    return { label: row, value: row, content: row}
                  });
                  setOptions((prevOptions:any) => ({ ...prevOptions, [index]: [...uniqueData ]}));
                  pageNumber = data?.data?.pagination.currentPage;
                  if(data?.data?.pagination.totalPages === data?.data?.pagination.currentPage){
                    setHasMore(false);
                  } 
              }
              setLoading(false);
    }
    if(hasMore){
      setLoading(true);
      apiCall()
    }
  }
  return (
    <Select
      style={{width: '200px'}}
      selected={selectedValue}
      onSelect={selectOptionChange}
      isScrollable={true}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      toggle={(toggleRef) => (
        <MenuToggle ref={toggleRef as React.Ref<MenuToggleElement>} onClick={() => setIsOpen((prev) => !prev)} style={{ minWidth: '23vw', background: 'white' }}>
         { selectedValue ? selectedValue : "Enter value"} 
        </MenuToggle>
      )}
    >
       <div style={{ padding: "8px", borderBottom: "1px solid #ccc", background: "#fff", position: "sticky", top: 0, zIndex: 10 }} className="search-input-infinte">
       <TextInput
          type="text"
          onChange={(_, value) => debouncedSearch(value, colKey, index)}
          placeholder="Search..."
        />
      </div>
      <div id="scrollableDiv" className="custom-dropdown"  style={{ maxHeight: "400px", overflow: "auto" }}>
        <InfiniteScroll
          dataLength={filteredOptions?.length}
          next={fetchMoreOptions}
          hasMore={hasMore}
          loader={<SelectOption key="loading"></SelectOption>}
          scrollableTarget="scrollableDiv"
          
        >
            {
                isLoading && (<>Loading....</>)
            }
            {
                !isLoading && (<SelectList>
                    {filteredOptions?.map((option:any) => (
                      <SelectOption key={option.value} value={option.value} >
                        {option.content}
                      </SelectOption>
                    ))}
                  </SelectList>)
            }
         
        </InfiniteScroll>
        {filteredOptions?.length === 0 && (
          <SelectOption isDisabled key="no-results">
            No results found
          </SelectOption>
        )}
      </div>
    </Select>
  );
};

export default InfiniteScrollSearchDropdown;
