import {
  ActionComponent,
  CheckboxElement,
  LinkComponent,
  LockButton,
  PriorityElements,
  RcaButton,
  UserProfile,
} from "../shared/data-table/DataTableActions";
export type DataTableType = "masterDataView" | "mdmDetailsViews" | "mdmTableHistoryViews" | "errorLifeCycle" | "elcDetails" | "poc-project" | "poc-repo";


const columnTypes = (columnType: string) => {
  if(['string', 'varchar', 'VARCHAR2', 'VARCHAR1', 'varchar2', 'varchar1'].includes(columnType.toLocaleLowerCase())) {
    return 'text';
  }
  if(['numeric', 'int', 'NUMBER','number'].includes(columnType.toLocaleLowerCase())) {
    return 'number';
  }
  if(['timestamp', 'localdate','TIMESTAMP(6)', 'timestamp(6)'].includes(columnType.toLocaleLowerCase())) {
    return 'date';
  }
}

const sortedList = (list:any, orderArray: any) =>
   list.sort((a:any, b:any) => {
  // Get the index of the key value in orderArray (if not found, return Infinity)
  const indexA = orderArray.findIndex((order:any) => a.key.includes(order));
  const indexB = orderArray.findIndex((order:any) => b.key.includes(order));

  // If neither value is found, they are considered equal (keep their order)
  if (indexA === -1 && indexB === -1) return 0;

  // If one value is found, it should come first
  if (indexA === -1) return 1;
  if (indexB === -1) return -1;

  // Otherwise, sort by their index in the orderArray
  return indexA - indexB;
});

export const GetDataColumns = (metaData: any, tableName: DataTableType) => {
   if (!metaData.length) return [];
   const columns: any[] = [];
   if (tableName === "mdmDetailsViews") {
    columns.push({
      header: 'checkedAll',
      key: 'checkedAll',
      limit: 10,
      type: Boolean,
      primary: false,
      isNotEdit:  true,
      required:  false,
      component: (props: any) => <CheckboxElement {...props}  />,
    });    
  }
   metaData.map((col:any) =>{
    const { key, required, columnLimit, columnType, primary } = col;
    const splitWords = key.split(/(?=[A-Z])|[_-]/);
    let header = splitWords
      .map((word:string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
      if(key === 'rca') {
        header = 'RCA';
      }

      // view history 
     if(tableName === 'mdmTableHistoryViews'){
        const displayItems = ['userId', 'role', 'commitRecord', 'commitReason', 'updatedDate'];
        if(displayItems.includes(key)){
          if(key === 'commitRecord'){
            columns.push({
              required: required || true,
              primary,
              header: header,
              key: key,
              component: (props: any) => <LinkComponent {...props} link={'/viewHistory'} />,
            });
          } else if(key === 'userId') {
            columns.push({
              required: required || true,
              primary,
              header: 'User ID',
              key: key,
              component: (props: any) => <UserProfile {...props} className="custom-author" />,
            });
          }
          else {
            columns.push({
              header: header,
              key: key,
              limit: columnLimit,
              type: columnTypes(columnType),
              primary,
              isNotEdit: primary ? true : false,
              required: primary ? false : true,
            });
          }
        }
      }
      else if(key === 'commitRecord'){
        columns.push({
          required: required || true,
          primary,
          header: header,
          key: key,
          component: (props: any) => <LockButton {...props}  />,
        });
      }
      else if(key === 'author'){
        columns.push({
          required: required || true,
          primary,
          header: header,
          key: key,
          component: (props: any) => <UserProfile {...props} className="custom-author" />,
        });
      }
      else if(["poc-project", "poc-repo" ].includes(tableName) && key === 'file'){
        if(tableName === "poc-repo") {
          columns.push({
            required: required || true,
            primary,
            header: header,
            key: key,
            component: (props: any) => <LinkComponent {...props} link={'/git-repo'} />,
          });
        } else if(tableName ==="poc-project") {
          columns.push({
            required: required || true,
            primary,
            header: header,
            key: key,
            component: (props: any) => <LinkComponent {...props} link={'/git-project'} />,
          });
        }
       
      }
      else if(['elcDetails'].includes(tableName) && ['priority', 'assignedTo', 'rca'].includes(key)){
          if(key === 'rca') {
            columns.push({
              required: required || true,
              primary,
              header: header,
              key: key,
              component: (props: any) => <RcaButton {...props}  />,
            });
          }
          else if(key === 'priority') {
            columns.push({
              required: required || true,
              primary,
              header: header,
              key: key,
              component: (props: any) => <PriorityElements {...props}  />,
            });
          } else {
            columns.push({
              required: required || true,
              primary,
              header: header,
              key: key,
              component: (props: any) => <UserProfile {...props}  />,
            });
          }
      }
      else if(['errorLifeCycle', 'masterDataView'].includes(tableName) && ['name', 'alarmId'].includes(key)){
          let link: string;
          if(tableName === "masterDataView"){
              link = "/mdm";
          } else {
            link = '/elc';
            columns.push({
              header: 'checkedAll',
              key: 'checkedAll',
              limit: 10,
              type: Boolean,
              primary,
              isNotEdit:  false,
              required:  true,
              component: (props: any) => <CheckboxElement {...props}  />,
            });
          }
        columns.push({
          required: required || true,
          primary,
          header: header,
          key: key,
          component: (props: any) => <LinkComponent {...props} link={link} />,
        });
      } else {
        columns.push({
          header: header,
          key: key,
          limit: columnLimit,
          type: columnTypes(columnType),
          primary,
          isNotEdit: primary ? true : false,
          required: primary ? false : true,
        });
  }
});

if (tableName === "mdmDetailsViews" || tableName=== "errorLifeCycle") {
  let _props:any = {
    isCheckBoxDispaly: false,
  };

  let actionsList = [{
    key: 'isNotifyDisplay',
  },
  {
    key: 'swapIcons',
  },
  {
    key: 'isEditDisplay',
  },
  {
    key: 'isCheckBoxDispaly',
  },
  {
    key: 'isDeleteDisplay',
  }
]
  if(tableName === 'errorLifeCycle'){
    actionsList = sortedList(actionsList.filter(row => ['isNotifyDisplay','swapIcons', 'isDeleteDisplay'].includes(row.key)),  ['isNotifyDisplay','swapIcons', 'isDeleteDisplay']);
  } else {
    actionsList = sortedList(actionsList.filter(row => ['isEditDisplay', 'isDeleteDisplay'].includes(row.key)),  ['isEditDisplay', 'isDeleteDisplay']);
  }
  columns.push({
    primary: false,
    header: "Action",
    key: "action",
    required: false,
    isNotEdit: true,
    // isNotDisplay: true,
    component: (props: any) => (
      <ActionComponent {..._props } {...props } actionsList={actionsList}/>
    ),
  });
}
return columns;
};
