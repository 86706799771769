import React, { useState } from "react";
import { Button, TextInput, CalendarMonth, Dropdown, DropdownList, MenuItem } from "@patternfly/react-core";
import { AngleDownIcon, CalendarAltIcon, UndoIcon } from "@patternfly/react-icons";
import { DropdownToggle } from "@patternfly/react-core/deprecated";

const DatePicker: React.FC = () => {
    const [isQuickSelectOpen, setQuickSelectOpen] = useState<boolean>(false);
    const [isAbsolutePickerOpen, setAbsolutePickerOpen] = useState(false);
    const [quickSecondDropDown, setQuickSecondDropDown] = useState(false);
    const [quickFilters, setQuickFilters] = useState<any>({
        firstFilter: { value: 'Last', isOpen: false },
        secondFilter: { value: 24 },
        thirdFilter: { value: 'Hours', isOpen: false }
    });
    const [quickSecondDropDownValue, setQuickSecondDropDownValue] = useState('Hours');
    const [selectedDate, setSelectedDate] = useState<any>('Last 24 Hours');
    const [quickSelectedVal, setQuickSelectedVal] = useState({
        status: true,
        value: 'Last 24 Hours'
    });
    const [isInputPickerOpens, setInputPickers] = useState<any>({
        absolute: true,
        relative: false,
        now: false
    });

    const toggleQuickDatePicker = () => {
        setQuickSelectedVal({
            ...quickSelectedVal,
            status: isQuickSelectOpen
        });
        setQuickSelectOpen(!isQuickSelectOpen);
        setAbsolutePickerOpen(false);
        setAbsolutePickerOpen(false);
        setInputPickers({
            absolute: false,
            relative: false,
            now: false
        });
    };

    const toggleAbsolutePicker = () => {
        setQuickSelectedVal({
            ...quickSelectedVal,
            status: false
        });
        setAbsolutePickerOpen(!isAbsolutePickerOpen);
        setQuickSelectOpen(false);
        setInputPickers({
            absolute: true,
            relative: false,
            now: false
        });
    };

    const quickStartDropSet = (value: any, key: string) => {
        setQuickFilters({ ...quickFilters, [key]: { value, isOpen: false } })
    }
    const quickSecondStartDropSet = (value: any) => {
        setQuickSecondDropDownValue(value);
        setQuickSecondDropDown(false);
    }

    const generateTimeSlots = () => {
        const timeSlots: string[] = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const hourString = hour < 10 ? `0${hour}` : `${hour}`;
                const minuteString = minute === 0 ? '00' : '30';
                timeSlots.push(`${hourString}:${minuteString}`);
            }
        }

        return timeSlots;
    };
    const openNativeEl = (key: string) => {
        const objs: any = {
            absolute: false,
            relative: false,
            now: false
        };
        objs[key] = true;
        setInputPickers((prev: any) => objs);
    }

    const calenderTimeChange = (date: any) => {
        setSelectedDate(date)
    }
    const widthPicker = () => {
        if (quickSelectedVal.status) {
            return '30%'
        }
        return '65%'
    }

    const getFormattedDateTime = () => {
        const now = new Date();

        // Format date as "Jan 30, 2025"
        const dateFormatter = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        });
        const formattedDate = dateFormatter.format(now);

        // Get hours, minutes, seconds, and milliseconds
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

        return `${formattedDate} @ ${hours}:${minutes}:${seconds}${milliseconds}`;
    }

    const staticStartDate = (<div style={{ display: "flex", marginTop: '10px', textAlign: 'left' }} className="start-date-elements">
        <div style={{ width: '19%', backgroundColor: '#D3D3D3', textAlign: 'center', paddingTop: '4px', fontSize: 'small', fontWeight: 'bold' }}>
            Start date
        </div>
        <TextInput style={{ width: '100%', position: 'relative', paddingTop: '5px', background: '#F9FBFD', color: 'grey' }} value={getFormattedDateTime()} readOnly />

    </div>);

    const quickCommonValueSelect = (value: any) => {
        setQuickSelectedVal({
            status: true,
            value
        });
        setAbsolutePickerOpen(false);
        setQuickSelectOpen(false);
        setInputPickers({
            absolute: false,
            relative: false,
            now: false
        });
    }

    const openDatePickerParent = (event: any) => {
        const target = event.target as HTMLElement;
        if (target.classList.contains("calender-center-elements") || target.classList.contains("cal-full-width-content")) {
            toggleAbsolutePicker();
        }
    }
    
    return (
        <div style={{ position: "relative", width: widthPicker(), marginLeft: 'auto' }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{  width: '89%', border: '1px solid #D3D3D3'}} className="date-picker-container">

                    <div style={{ display: "inline-flex", width: "100%" }}  className="calender-center-elements" onClick={openDatePickerParent}>
                        <span onClick={toggleQuickDatePicker} className="calender-icons-input">
                            <CalendarAltIcon />
                            <AngleDownIcon />
                        </span>
                        <span className="cal-full-width-content" onClick={toggleAbsolutePicker}>{quickSelectedVal.value}</span>
                    </div>
                </div>
             
                <Button variant="plain" style={{ background: '#F9FBFD', marginLeft: 'auto', height: '37px',  border: '1px solid #D3D3D3', borderRadius: '6px' }}>
                    <UndoIcon style={{ transform: "scaleX(-1)" }} />
                </Button>
        


            </div>

            {isQuickSelectOpen && (
                <div style={{ position: "absolute", top: "40px", left: "0", background: "#fff", border: "1px solid #ddd", padding: "10px", width: "100%", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "5px", zIndex: 1000 }}>
                    <div style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "8px" }}>Quick select</div>
                    <div className="custom-row" style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
                        <div className="dropdown-elements">
                            <Dropdown
                                style={{ width: '100%' }}
                                isOpen={quickFilters.firstFilter.isOpen}
                                toggle={(toggleRef) => (
                                    <DropdownToggle style={{ width: '100%' }} id="dropdown-toggle" ref={toggleRef} onToggle={() => setQuickFilters({ ...quickFilters, firstFilter: { ...quickFilters.firstFilter, isOpen: true } })} >
                                        {quickFilters.firstFilter.value}
                                    </DropdownToggle>
                                )}

                            >
                                <DropdownList style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: '0',             // Align dropdown with button
                                    zIndex: 9999,          // Ensure dropdown is on top
                                    marginTop: '4px',      // Add space between dropdown and button
                                    backgroundColor: '#ffffff',  // Set background color for dropdown
                                    border: '1px solid #d2d2d2', // Add border for dropdown
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)'
                                }}>
                                    <MenuItem onClick={() => quickStartDropSet('Next', 'firstFilter')}>Next</MenuItem>
                                    <MenuItem onClick={() => quickStartDropSet('Last', 'firstFilter')}>Last</MenuItem>
                                </DropdownList>
                            </Dropdown>
                        </div>
                        <div style={{ width: '20%' }}>
                            <TextInput min={0} type="number" value={quickFilters.secondFilter.value} style={{ height: '30px', border: '1px solid #D3D3D3' }} onChange={(event: any) => quickStartDropSet(event.target.value, 'secondFilter')} />
                        </div>

                        <div className="dropdown-elements">
                            <Dropdown
                                style={{ width: '100%' }}
                                isOpen={quickFilters.thirdFilter.isOpen}
                                onOpenChange={console.log} // Handles opening and closing
                                toggle={(toggleRef) => (
                                    <DropdownToggle id="dropdown-toggle" ref={toggleRef} onToggle={() => setQuickFilters({ ...quickFilters, thirdFilter: { ...quickFilters.thirdFilter, isOpen: true } })} style={{ width: '100%' }}>
                                        {quickFilters.thirdFilter.value}
                                    </DropdownToggle>
                                )}
                            //align="right"
                            >
                                <DropdownList style={{
                                    position: 'absolute',
                                    top: '100%',
                                    minWidth: '150px',
                                    right: '0',
                                    zIndex: 9999,
                                    marginTop: '4px',
                                    backgroundColor: '#ffffff',
                                    border: '1px solid #d2d2d2',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', 
                                    width: '100%'
                                }}>
                                    <MenuItem onClick={() => quickStartDropSet('Seconds', 'thirdFilter')}>Seconds</MenuItem>
                                    <MenuItem onClick={() => quickStartDropSet('Minutes', 'thirdFilter')}>Minutes</MenuItem>
                                    <MenuItem onClick={() => quickStartDropSet('Hours', 'thirdFilter')}>Hours</MenuItem>
                                    <MenuItem onClick={() => quickStartDropSet('Days', 'thirdFilter')}>Days</MenuItem>
                                    <MenuItem onClick={() => quickStartDropSet('Weeks', 'thirdFilter')}>Weeks</MenuItem>
                                    <MenuItem onClick={() => quickStartDropSet('Months', 'thirdFilter')}>Months</MenuItem>
                                    <MenuItem onClick={() => quickStartDropSet('Years', 'thirdFilter')}>Years</MenuItem>
                                </DropdownList>
                            </Dropdown>
                        </div>
                        <Button style={{ width: '20%', background: '#CDE4F5', color: '#0161A6' }} onClick={() => quickCommonValueSelect(`${quickFilters.firstFilter.value} ${quickFilters.secondFilter.value}  ${quickFilters.thirdFilter.value}`)}>Apply</Button>
                    </div>
                    <hr style={{ margin: "10px 0" }} />
                    <div style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "5px" }}>Commonly used</div>
                    <div className="custom-row" style={{ display: 'flex', alignItems: 'left', gap: '10px', width: '100%' }}>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link" ><span onClick={() => quickCommonValueSelect('Today')}>Today</span></Button>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 24 hours')}>Last 24 hours</span></Button>
                    </div>
                    <div className="custom-row" style={{ display: 'flex', alignItems: 'left', gap: '10px', width: '100%' }}>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('This Week')}>This Week</span></Button>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 7 days')}>Last 7 days</span></Button>
                    </div>
                    <div className="custom-row" style={{ display: 'flex', alignItems: 'left', gap: '10px', width: '100%' }}>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 1 minutes')}>Last 1 minutes</span></Button>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 30 days')}>Last 30 days</span></Button>
                    </div>
                    <div className="custom-row" style={{ display: 'flex', alignItems: 'left', gap: '10px', width: '100%' }}>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 15 minutes')}>Last 15 minutes</span></Button>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 90 days')}>Last 90 days</span></Button>
                    </div>
                    <div className="custom-row" style={{ display: 'flex', alignItems: 'left', gap: '10px', width: '100%' }}>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 30 minutes')}>Last 30 minutes</span></Button>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 1 year')}>Last 1 year</span></Button>
                    </div>
                    <div className="custom-row" style={{ display: 'flex', alignItems: 'left', gap: '10px', width: '100%' }}>
                        <Button className="common-used-button-link" style={{ width: '50%', textAlign: 'left' }} variant="link"><span onClick={() => quickCommonValueSelect('Last 1 Hour')}>Last 1 Hour</span></Button>
                    </div>
                </div>
            )}

            {isAbsolutePickerOpen && (
                <div style={{ position: "absolute", top: "40px", left: "0", background: "#fff", border: "1px solid #ddd", padding: "10px", width: "100%", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "5px", zIndex: 1000 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "5px", borderBottom: '1px solid #ccc' }}>
                        <div className={`${isInputPickerOpens.absolute ? 'custom-picker-active-class' : 'custom-picker-inactive-class'}`}> <Button variant="plain" style={{ fontWeight: "bold" }} onClick={() => openNativeEl('absolute')}>Absolute</Button> </div>
                        <div className={`${isInputPickerOpens.relative ? 'custom-picker-active-class' : 'custom-picker-inactive-class'}`}> <Button variant="plain" style={{ fontWeight: "bold" }} onClick={() => openNativeEl('relative')}>Relative</Button> </div>
                        <div className={`${isInputPickerOpens.now ? 'custom-picker-active-class' : 'custom-picker-inactive-class'}`}>  <Button variant="plain" style={{ fontWeight: "bold" }} onClick={() => openNativeEl('now')}>Now</Button> </div>

                    </div>
                    {
                        isInputPickerOpens.absolute && (<>
                            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "5px" }}>
                                <CalendarMonth
                                    date={selectedDate || new Date()}
                                    onChange={(event, date) => calenderTimeChange(date)}
                                />
                                <div style={{ width: '100%', overflowX: 'auto', height: '280px', marginTop: '6%', border: '1px solid #D3D3D3' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }} className="absulate-time-picker">
                                        {generateTimeSlots().map((time, index) => (
                                            <div key={index} style={{ padding: '8px 12px', width: '100%', textAlign: 'center' }} className="time-slot-random">
                                                <span className={`${index === 0 ? 'active-time' : ''}`}>{time}</span>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>
                            {
                                staticStartDate
                            }
                        </>

                        )
                    }

                    {
                        isInputPickerOpens.relative && (
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: '10px' }}>
                                    <div style={{ width: '48%', border: '1px solid #D3D3D3', display: 'inline-block' }}>
                                        <TextInput min={0} type="number" style={{ height: '28px' }} value={24} />
                                    </div>
                                    <div style={{ width: '48%' }} className="dropdown-elements">
                                        <Dropdown
                                            style={{ width: '100%' }}
                                            isOpen={quickSecondDropDown}
                                            onOpenChange={console.log} // Handles opening and closing
                                            toggle={(toggleRef) => (
                                                <DropdownToggle id="dropdown-toggle" ref={toggleRef} onToggle={() => setQuickSecondDropDown(prev => !prev)} style={{ width: '100%' }}>
                                                    {quickSecondDropDownValue}
                                                </DropdownToggle>
                                            )}
                                        >
                                            <DropdownList style={{
                                                position: 'absolute',
                                                top: '100%',
                                                minWidth: '150px',
                                                right: '0',
                                                zIndex: 9999,
                                                marginTop: '4px',
                                                backgroundColor: '#ffffff',
                                                border: '1px solid #d2d2d2',
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                                width: '100%'
                                            }}>
                                                <MenuItem onClick={() => quickSecondStartDropSet('Seconds')}>Seconds</MenuItem>
                                                <MenuItem onClick={() => quickSecondStartDropSet('Minutes')}>Minutes</MenuItem>
                                                <MenuItem onClick={() => quickSecondStartDropSet('Hours')}>Hours</MenuItem>
                                                <MenuItem onClick={() => quickSecondStartDropSet('Days')}>Days</MenuItem>
                                                <MenuItem onClick={() => quickSecondStartDropSet('Weeks')}>Weeks</MenuItem>
                                                <MenuItem onClick={() => quickSecondStartDropSet('Months')}>Months</MenuItem>
                                                <MenuItem onClick={() => quickSecondStartDropSet('Years')}>Years</MenuItem>
                                            </DropdownList>
                                        </Dropdown>
                                    </div>

                                </div>
                                {
                                    staticStartDate
                                }
                            </>
                        )
                    }

                    {
                        isInputPickerOpens.now && (<>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '10px' }}>
                                <div className="now-paragrap">
                                    <p>Setting the time to "now" means that on every refresh this time will be set to the time of the refresh.</p>
                                </div>


                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: '10px' }}>
                                <Button className="now-start-button">Set Start date and time to now</Button>

                            </div>
                        </>)
                    }


                </div>
            )}
        </div>
    );
};

export default DatePicker;
