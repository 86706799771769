import axios, {AxiosError, AxiosResponse} from "axios";
import {License} from "./LicenseModels";
import {SsoApi} from "./SsoApi";
import {
    MDMResult,
    PaginatedResult,
    PaginationRequest,
    ProjectDeadletterMessage,
    ProjectDeadletterMessageFilter, ProjectFile, ProjectLogFilter, ProjectLogResult
} from "./ProjectModels";
import {ProcessedEntity, ProcessedEntityFilter} from "./ProcessedEntityModels";
import {
    MilestoneProduct,
    MilestoneProductFilter,
    MilestoneProductStep,
    MilestoneProductStepFilter, MilestoneStepAssign,
    MilestoneProductHistory
} from "./MilestoneProductModels";
import {  MDMHistoryFilter, MDMHistoryList, MDMTableDetailsFilter, MDMTableFilter, MDMTableList } from "./mdm/Modals";
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
const instance = axios.create();

export class SmhApi {
    static setAuthType(authType: string) {
        console.log("SMHApi: authType", authType);
        switch (authType){
            case "public": {
                SmhApi.setPublicAuthentication();
                break;
            }
            case "oidc": {
                SmhApi.setOidcAuthentication();
                break;
            }
        }
    }
    static setPublicAuthentication() {
    }
    static setOidcAuthentication() {
        instance.interceptors.request.use(async config => {
                config.headers.Authorization = 'Bearer ' + SsoApi.keycloak?.token;
                return config;
            },
            error => {
                Promise.reject(error)
            });

        instance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if ((error?.response?.status === 403 || error?.response?.status === 401) && !originalRequest._retry) {
                console.log("error", error)
                return SsoApi.keycloak?.updateToken(30).then(refreshed => {
                    if (refreshed) {
                        console.log('SsoApi', 'Token was successfully refreshed');
                    } else {
                        console.log('SsoApi', 'Token is still valid');
                    }
                    originalRequest._retry = true;
                    return instance(originalRequest);
                }).catch(reason => {
                    console.log('SsoApi', 'Failed to refresh token: ' + reason);
                });
            }
            return Promise.reject(error);
        });
    }

    static async getLicense(): Promise<[Error | null, License | null]> {
        return instance.get('/ui/smh/license', {})
            .then((res) => {
                if (res !== undefined && res.status === 200) {
                    return [null, res.data as License] as [null, License]
                } else {
                    return [Error("License fetch failed"), null] as [Error, null]
                }
            }).catch(err => {
            console.log(err);
            return [Error("License fetch failed"), null] as [Error, null]
        });
    }

    static async postLicense(license: License): Promise<[Error | null, License | null]> {
        return instance.post('/ui/smh/license', {
            key: license.key
        })
            .then(res => {
                if(res.status === 200 || res.status === 201) {
                    return [null, res.data as License] as [null, License]
                } else if(res.status === 400) {
                    return [Error("Invalid license key."), null] as [Error, null]
                } else {
                    return [Error("Invalid license key."), null] as [Error, null]
                }
            }).catch(err => [err as Error, null] as [Error, null]);
    }

    static async deleteLicense(): Promise<Error | null> {
        return instance.delete('/ui/smh/license')
            .then(res => {
                if (res.status === 204 || res.status === 200) {
                    return null;
                } else if(res.status === 403) {
                    return Error("Forbidden Action");
                } else {
                    return null;
                }

            }).catch(err => {
                return Error(err);
            });
    }

    static async getProjectLogs(projectId: string, searchAfter: string[], filter: ProjectLogFilter, after: (result: ProjectLogResult) => void) {
        instance.get('/ui/log/' + projectId, {
            params: {
                from: filter.from,
                to: filter.to,
                level: (filter.level && filter.level !== '' && filter.level !== 'Select level') ? filter.level: undefined,
                message: (filter.message && filter.message !== '') ? filter.message: undefined,
                searchAfter: searchAfter
            }})
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                }
            }).catch(err => {
            console.log(err);
        });
    }

    static async getProjectDeadletterMessages(projectId: string, pagination: PaginationRequest, filter: ProjectDeadletterMessageFilter, after: (result: PaginatedResult<ProjectDeadletterMessage>) => void) {
        instance.get('/ui/smh/deadletter/' + projectId + '/messages', {
            params: {
                from: filter.from,
                to: filter.to,
                exchangeId: (filter.exchangeId && filter.exchangeId !== '') ? filter.exchangeId: undefined,
                messageId: (filter.messageId && filter.messageId !== '') ? filter.messageId: undefined,
                routeId: (filter.routeId && filter.routeId !== '') ? filter.routeId: undefined,
                status: (filter.status && filter.status !== '') ? filter.status: undefined,
                page: pagination.page -1,
                size: pagination.size
            }})
            .then(res => {
                after(res.data);
            }).catch(err => {
            console.log(err);
        });
    }

    static async redeliverDeadletterMessages(projectId: string, messageIds: number[]) {
        return instance.post('/ui/smh/deadletter/' + projectId + '/messages/redeliver', messageIds, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
                return res.data;
            }).catch(err => {
                console.log(err);
            });
    }

    static async deleteDeadletterMessage(projectId: string, messageId: number) {
        return instance.delete('/ui/smh/deadletter/' + projectId + '/messages/' + messageId, {})
            .then(res => {
                return res.data;
            }).catch(err => {
                console.log(err);
            });
    }

    static async getProcessedEntities(pagination: PaginationRequest, filter: ProcessedEntityFilter, after: (result: PaginatedResult<ProcessedEntity>) => void) {
        instance.get('/ui/smh/processed-messages/', {
            params: {
                from: filter.from,
                to: filter.to,
                processorName: (filter.processorName && filter.processorName !== '') ? filter.processorName: undefined,
                messageId: (filter.messageId && filter.messageId !== '') ? filter.messageId: undefined,
                page: pagination.page -1,
                size: pagination.size
            }})
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                }
            }).catch(err => {
            console.log(err);
        });
    }

    static async deleteProcessedEntity(processedEntity: ProcessedEntity): Promise<Error | null> {
        return instance.delete('/ui/smh/processed-messages/' + processedEntity.id.processorName + '/' + processedEntity.id.messageId, )
            .then(res => {
                if (res.status === 204 || res.status === 200) {
                    return null;
                } else if(res.status === 403) {
                    return Error("Forbidden Action");
                } else {
                    return null;
                }

            }).catch(err => {
                return Error(err);
            });
    }


    static async getMilestoneProducts(pagination: PaginationRequest, filter: MilestoneProductFilter, after: (result: PaginatedResult<MilestoneProduct>) => void) {
        instance.get('/ui/smh/milestone/product', {
            params: {
                from: filter.from,
                to: filter.to,
                name: (filter.name && filter.name !== '') ? filter.name: undefined,
                description: (filter.description && filter.description !== '') ? filter.description: undefined,
                page: pagination.page -1,
                size: pagination.size
            }})
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                }
            }).catch(err => {
            console.log(err);
        });
    }

    static async getMilestoneProduct(id: string,  after: (result: MilestoneProduct) => void) {
        instance.get('/ui/smh/milestone/product/' + id)
            .then(res => {
                if (res.status === 200) {
                    after(res.data);
                }
            }).catch(err => {
            console.log(err);
        });
    }

    static async deleteMilestoneProduct(milestoneProduct: MilestoneProduct): Promise<Error | null> {
        return instance.delete('/ui/smh/milestone/product/' + milestoneProduct.id )
            .then(res => {
                if (res.status === 204 || res.status === 200) {
                    return null;
                } else if(res.status === 403) {
                    return Error("Forbidden Action");
                } else {
                    return null;
                }

            }).catch(err => {
                return Error(err);
            });
    }

    static async importMilestoneProduct(milestoneProduct: MilestoneProduct, formData: FormData): Promise<[AxiosError | null, MilestoneProduct | null]> {
        return instance.post(`/ui/smh/milestone/product/${milestoneProduct.productId}/import?description=${milestoneProduct.description}&name=${milestoneProduct.name}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(res => {
                if(res.status === 200 || res.status === 201) {
                    return [null, res.data as MilestoneProduct] as [null, MilestoneProduct]
                } else if(res.status === 400) {
                    return [res.data as AxiosError, null] as [AxiosError, null]
                } else {
                    return [Error("import failed."), null] as [AxiosError, null]
                }
            }).catch(err => [err as AxiosError, null] as [AxiosError, null]);
    }

    static async cloneMilestoneProduct(sourceProductId: string, milestoneProduct: MilestoneProduct): Promise<[AxiosError | null, MilestoneProduct | null]> {
        return instance.post(`/ui/smh/milestone/product/clone/${sourceProductId}`, milestoneProduct, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            if(res.status === 200 || res.status === 201) {
                return [null, res.data as MilestoneProduct] as [null, MilestoneProduct]
            } else if(res.status === 400) {
                return [res.data as AxiosError, null] as [AxiosError, null]
            } else {
                return [Error("clone failed."), null] as [AxiosError, null]
            }
        }).catch(err => [err as AxiosError, null] as [AxiosError, null]);
    }

    static async getMilestoneProductSteps(productId: string, pagination: PaginationRequest, filter: MilestoneProductStepFilter, after: (result: PaginatedResult<MilestoneProductStep>) => void) {
        instance.get(`/ui/smh/milestone/product/${productId}/step`, {
            params: {
                from: filter.from,
                to: filter.to,
                name: (filter.name && filter.name !== '') ? filter.name: undefined,
                description: (filter.description && filter.description !== '') ? filter.description: undefined,
                page: pagination.page -1,
                size: pagination.size
            }}).then(res => {
            after(res.data);
        }).catch(err => {
            console.log(err);
        });
    }


    static async getMilestoneCandidates(productId: string, productStepId: string, pagination: PaginationRequest, filter: MilestoneProductStepFilter) {
        return instance.get(`/ui/smh/milestone/product/${productId}/step/${productStepId}/milestone-candidates`, {
            params: {
                from: filter.from,
                to: filter.to,
                name: (filter.name && filter.name !== '') ? filter.name: undefined,
                description: (filter.description && filter.description !== '') ? filter.description: undefined,
                page: pagination.page - 1,
                size: pagination.size
            }}).then(res => {
            if(res.status === 200 || res.status === 201) {
                return [ res.data as PaginatedResult<MilestoneProductStep>, null] as [PaginatedResult<MilestoneProductStep>, null]
            } else if(res.status === 400) {
                return [null, res.data as AxiosError] as [null, AxiosError]
            } else {
                return [null, Error("Get milestone candidates failed.")] as [null, AxiosError]
            }
        }).catch(err => [null, err as AxiosError] as [null, AxiosError]);
    }

    static async assignMilestone(productId: string, assignMilestone: MilestoneStepAssign): Promise<AxiosError | null> {
        return instance.put(`/ui/smh/milestone/product/${productId}/step/milestone/assign`, assignMilestone).then(res => {
            if(res.status === 200 || res.status === 201) {
                return null;
            } else if(res.status === 400) {
                return res.data as AxiosError as AxiosError
            } else {
                return Error("Milestone assign failed") as AxiosError
            }
        }).catch(err => err as AxiosError);
    }

    static async getMilestoneProductHistory(
        productId: string,
         pagination: PaginationRequest,
        after: (result: PaginatedResult<MilestoneProductHistory>) => void
      ) {
        instance
          .get(`/ui/smh/milestone/product/${productId}/step/history`, {
            params: {
              page: pagination.page - 1,
              size: pagination.size,
            },
          })
          .then((res) => {
            after(res.data);
          })
          .catch((err) => {
            return Error(err);
          });
      }


    static async getIntegrationsCount(after: (result: number) => void) {
        instance.get('/ui/smh/integrations/count', {})
            .then(res => {
                after(res.data);
            }).catch(err => {
            console.log(err);
        });
    }

    static async getAllIntegrationsFiles(after: (files: ProjectFile[]) => void) {
        instance.get('/ui/smh/integrations/files', {})
            .then(res => {
                after(res.data);
            }).catch(err => {
            console.log(err);
        });
    }

    static enableProjectAddOn(projectId: string, addOnType: string, after: (res: AxiosResponse<any>) => void) {
        instance.put(`/ui/smh/project/${projectId}/addons/enable?addOnType=${addOnType}`
            ).then(res => {
                after(res);
            }).catch(err => {
                console.log(err);
        });
    }

    static disableProjectAddOn(projectId: string, addOnType: string, after: (res: AxiosResponse<any>) => void) {
        instance.put(`/ui/smh/project/${projectId}/addons/disable?addOnType=${addOnType}`
        ).then(res => {
            after(res);
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * @param secret
     * @param after as @callback
     * @purpose @this function is using on addOn screen for encrypting text.
     */
    static async encryptValue(value: string, after: (res: AxiosResponse<any> | any) => void) {
        instance.post(`/ui/smh/encrypt/value`, {value}
        ).then(res => {
            after(res);
        }).catch(err => {
            after(err);
        });
    }

    static async getCommitHistory(filePath: string, after: (res: AxiosResponse<any> | any) => void) {
        instance.get('/ui/smh/file/' + filePath)
                .then(res => {
                    if (res.status === 200) {
                        after(res);
                    }
                }).catch(err => {
                console.log(err);
            });
    }
    static async getCommitDiff(commitId: string, after: (res: AxiosResponse<any> | any) => void) {
        instance.get('/ui/smh/file/commit/' + commitId)
                .then(res => {
                    if (res.status === 200) {
                        after(res);
                    }
                }).catch(err => {
                console.log(err);
            });
    }

    static async getMDMTableList(pagination: PaginationRequest, filter: MDMTableFilter, after: (result: MDMResult<MDMTableList>) => void) {
        instance.get('/ui/smh/mdm', {
            params: {
               
                searchTerm: (filter.searchTerm && filter.searchTerm !== '') ? filter.searchTerm: undefined,
                page: pagination.page -1,
                size: pagination.size
            }})
            .then((res:any) => {
                if (res.status === 200) {
                    after(res.data);
                }
            }).catch(err => {
                return Error(err);
        });
    }
    static async getMDMDetailsRecords(pagination: PaginationRequest, filter: MDMTableDetailsFilter): Promise<MDMResult<any>> {
       return instance.post('/ui/smh/mdm/detail/search',{filters: filter.filter}, {
            params: {
                tableName: (filter.tableName && filter.tableName !== '') ? filter.tableName: undefined,
                page: pagination.page -1,
                size: pagination.size,
                searchTerm: (filter.searchTerm && filter.searchTerm !== '') ? filter.searchTerm: undefined,
            },
        });
    }

    /**
     * 
     * @param tableName 
     * @param columnName 
     * @param page 
     * @param searchTerm 
     * @returns this API function returns data for specific column, search, and pagination.
     */
    static async getColumnRecords(tableName?: string, columnName?: string, page:number = 0,  searchTerm?:string | undefined ): Promise<MDMResult<any>> {
        return instance.get('/ui/smh/mdm/detail', {
             params: {
                 tableName: tableName,
                 page: page,
                 columnName: columnName,
                 searchTerm: searchTerm,
             },
       
         });
     }
    static async getMDMTableHistory(pagination: PaginationRequest, filter: MDMTableDetailsFilter) {
        //change to correct API
        return instance.get('/ui/smh/mdm/detail/fetchAll', {
             params: {
                 tableName: (filter.tableName && filter.tableName !== '') ? filter.tableName: undefined,
                 page: pagination.page -1,
                 size: pagination.size,
                 searchTerm: (filter.searchTerm && filter.searchTerm !== '') ? filter.searchTerm: undefined,
             }
         });
     }
     
    /**
    * 
    * @param pagination object
    * @param filter 
    * @param after as callback
    * @purpose this is for get all history
    * @return this returns to all history response
    */
       static async getHistory(pagination: PaginationRequest, filter: MDMHistoryFilter, after: (result: MDMResult<MDMHistoryList>) => void) {
        instance.get('/ui/smh/mdm/history', {
            params: {
                tableName: (filter.tableName && filter.tableName !== '') ? filter.tableName: undefined,
                searchTerm: (filter.searchTerm && filter.searchTerm !== '') ? filter.searchTerm: undefined,
                page: pagination.page -1,
                size: pagination.size
            }})
            .then((res:any) => {
                if (res.status === 200) {
                    after(res.data);
                }
            }).catch(err => {
                return Error(err);
        });
    }

    /**
     * 
     * @param tableName 
     * @param commitRecord 
     * @returns it will return all commit history.
     */
    static async getCommitViewHistory(tableName: string, commitRecord : string) {
        return instance.get(`/ui/smh/mdm/history/commit-diff?tableName=${tableName}&commitRecord=${commitRecord}`);
    }
    
    /**
     * 
     * @param ids or table uniue id or multiple ids
     * @param tableName 
     * @param commitReason 
     * @returns it will delete single record or if ids having as aray then it will be multiple records
     */
    static async deleteMDMData(ids: string | number | any[], tableName: string | undefined, commitReason: any, isDeleteAll: boolean): Promise<any> {
        if(isDeleteAll || Array.isArray(ids)){
            return instance.delete(`/ui/smh/mdm/detail/bulk-delete`, {data: { commitReason, ids: ids.toString(), isDeleteAll, tableName }});
        }
        return instance.delete(`/ui/smh/mdm/detail/delete/${tableName}/${ids}`, {data: { commitReason: commitReason }});
    }

    static async uploadMDMData(tableName: Partial<string | undefined>, files: File[]): Promise<any> {
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append('file', files[i]);
        }
        return instance.post(`/ui/smh/mdm/detail/bulk-import/${tableName}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    static async createMdmData(tableName: Partial<string | undefined>, savePayload:any): Promise<any> {
        return instance.post(`/ui/smh/mdm/detail/create/${tableName}`, savePayload);
    }
    
    
    static async editMdmData(tableName: Partial<string | undefined>, systemId: number,  savePayload:any): Promise<any> {
        return instance.put(`/ui/smh/mdm/detail/update/${tableName}/${systemId}`, savePayload);
    }
}