import React, { useEffect, useState } from "react";
import {
  TextInput,
  Button,
  ActionGroup,
  Grid,
  GridItem,
  DrawerPanelContent,
  DatePicker,
  Spinner,
} from "@patternfly/react-core";
import { TimesIcon } from "@patternfly/react-icons";
import "./EditCreateModal.css";
const styles = {
  DrawerPanelContent: { width: "40%", minWidth: "40%" },
  GridItem: { paddingLeft: "1rem" },
  GridItemDiv: { display: "flex", justifyContent: "space-between" },
  GridItemDivSpan: {
    width: "30%",
    fontSize: "medium",
    fontWeight: "bold",
  },
  TextInput: {
    border: "2px solid #D3D3D3",
    borderRadius: "6px",
    padding: "0.5rem",
  },
  commitReason: {
    border: "2px solid #D3D3D3",
    borderRadius: "6px",
    padding: "0.5rem",
    width: "92%",
    maxWidth: '92%'
  },
  DatePicker: {
    width: "100%",
  },
  ActionGroup: { paddingBottom: "20px", paddingLeft: "20px" },
  ActionGroupPrimaryButton: {
    padding: "0.75rem 1.5rem",
    fontSize: "1rem",
    marginRight: "1rem",
  },
  ActionGroupSecondaryButton: {
    padding: "0.75rem 1.5rem",
    fontSize: "1rem",
  },
  InputContainer: { width: "100%" },
};

interface FormProps {
  fields: Array<{
    key: string;
    header: string;
    required?: boolean | undefined;
    limit?: number | string | undefined;
    type?: any;
  }>;
  onSave: (data: Record<string, string | number>, isEdit?: boolean) => void;
  onCancel: () => void;
  editObject?: any;
}

const EditCreateModal: React.FC<FormProps> = ({
  fields,
  onSave,
  onCancel,
  editObject,
}) => {
  const initialState = fields.reduce((acc, field) => {
    acc[field.key] = "";
    acc['commitReason'] = "";
    return acc;
  }, {} as Record<string, string | number>);
  const [formData, setFormData] = useState(editObject || initialState);
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveCall, isSaveCallSet] = useState(false);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (editObject) {
      setFormData({ ...editObject, commitReason: editObject?.commitReason || '' });
      setIsEdit(true);
    } else {
      setFormData(initialState);
      setIsEdit(false);
    }
    return () => {
      setLoading(false);
      setFormData(initialState);
    };
  }, [editObject]);

  const handleChange = ({ target: { value } }: any, field: any) => {
    let _errors = { ...errors };
    const { key, type, limit, header } = field;

    if (_errors?.[key] && value.trim()) {
      delete _errors[key];
    } else if (!value.trim() && field?.required) {
      _errors[key] = `${header} is required.`;
    }

    if (type === "number" && /^\d*$/.test(value) && limit >= value.length) {
      setFormData({ ...formData, [key]: value ? value : "" });
    } else if (type === "number" && !/^\d*$/.test(value.trim())) {
      _errors[key] = `${header} type is number`;
    } else if (limit >= value.length) {
      setFormData({
        ...formData,
        [key]: value,
      });
    } else if (limit < value.length) {
      _errors[key] = `${header} is required maximum ${limit} char`;
    }
    if(field === 'commitReason' && value === ""){
      _errors['commitReason'] = `Commit Reason is required.`;
      setFormData({
        ...formData,
        ['commitReason']: '',
      });
    } else if(field === 'commitReason'){
      delete _errors.commitReason;
      setFormData({
        ...formData,
        ['commitReason']: value,
      });
    }
    if (isSaveCall) {
      setErrors(_errors);
    }
  };

  const isValidDate = (date: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format check
    if (!dateRegex.test(date)) return false;

    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime()); // Ensure it's a valid date
  };
  
  const onDatePickerOnChange = ({ target: { value } }: any, field: any) => {
    const today = new Date().toLocaleDateString('en-CA')?.split('T')?.[0];
    let _errors = { ...errors };
    const { key } = field;
    if (!isValidDate(value)) {
      _errors[key] = `Invalid date format. Use YYYY-MM-DD.`;
      setFormData({
        ...formData,
        [key]: '',
      });
      setErrors(_errors);
    } else if (key === 'createdDate' && value > today) {
      _errors[key] = `Future dates are not allowed.`;
      setErrors(_errors);
      setFormData({
        ...formData,
        [key]: '',
      });
    } else {
      delete _errors[key];
      setErrors(_errors);
      setFormData({
        ...formData,
        [key]: value,
      });
    }
  }

  const datePickerValue = (value: any) => {
    if(!value || value === '') return '';
    const date = new Date(value);
    const formattedDate = date?.toLocaleDateString('en-CA')?.split('T')?.[0] || '';
    return formattedDate;
 }

  const  formatTimestamps = (obj:any) => {
    const dateFields = fields.filter((dateObj:any) => dateObj.type === 'date').map((row:any) => row?.key);
    if(!dateFields.length) return obj;
    const result = { ...obj };
    dateFields.forEach((key: string) => {
      result[key] = datePickerValue(result[key]);
    });
    return result;
  }

  const handleSave = () => {
    const newErrors: Record<string, string> = {};
    fields
      .filter((row:any) => row.key !== "action" && !row?.isNotDisplay)
      .forEach((field) => {
        if (!formData[field.key] && field?.required) {
          newErrors[field.key] = `${field.header} is required.`;
        }
      });
    if (!formData['commitReason'] || formData['commitReason'] === '') {
      newErrors['commitReason'] = `Commit Reason is required.`;
    }
    setErrors(newErrors);
    isSaveCallSet(true);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      onSave(formatTimestamps(formData), isEdit);
      isSaveCallSet(false);
      setFormData(initialState);
      setTimeout(() => setLoading(false), 5000);
    }
  };

  const onClose = () => {
    setFormData(initialState);
    setErrors({});
    isSaveCallSet(false);
    onCancel && onCancel();
  };

  const getFiltersData = (data:any) => {
    if(isEdit) return data.filter((row:any) => row.key !== 'action' && row.key !== 'checkedAll');
    return data.filter((row:any) => !row?.isNotEdit)
  }

  return (
    <DrawerPanelContent
      style={styles.DrawerPanelContent}
      className="pf-m-panel-right create-mdm-data__dpc-container"
    >
      <span className="create-mdm-data__dpc-header">
        {
          !isLoading && (<h2>{isEdit ? "Edit" : "Create"}</h2>)
        }
        {
          isLoading && (<h2> Saving...<Spinner size="lg" aria-label="Saving..." /></h2>)
        }
         <TimesIcon onClick={onClose} style={{marginLeft: 'auto', height: '0.9em', width: '0.9em', cursor: 'pointer'}} />
      </span>
      <div className={`create-mdm-data__dpc-grid ${fields.length >= 10 ? 'mdm-list-more-10' : ''}`} >
        <Grid hasGutter >
          {getFiltersData(fields)?.map((field:any) => (
            <GridItem span={12} key={field.key} style={styles.GridItem}>
              <div style={styles.GridItemDiv}>
                <span className={`${field.required  ? "create-mdm-data__dpc-required-fields"   : "" }`} style={styles.GridItemDivSpan}>
                  {field.header}
                </span>

                {field.type !== "date" && (
                  <div className="mdm_input_fields create-mdm-data__input-contianer">
                    <TextInput
                      placeholder={`Enter ${field.type === 'text' ? 'text' : 'numeric value'}`}
                      value={formData?.[field.key]}
                      autoComplete="off"
                      isRequired={field.required}
                      type={field.type}
                      id={field.key}
                      name={field.key}
                      onChange={(value: any) => handleChange(value, field)}
                      readOnlyVariant={
                        field?.primary && isEdit
                          ? "default"
                          : undefined
                      }
                      style={styles.TextInput}
                      className="rectangle-input"
                    />

                    {errors?.[field.key] && (
                      <div className="mdm-validation">
                        {errors[field.key]}
                      </div>
                    )}
                  </div>
                )}

                {field.type === "date" && (
                  <div
                    style={styles.InputContainer}
                    className="create-mdm-data__input-contianer"
                  >
                    <DatePicker
                      className="pf-u-w-100 custom-datepicker"
                      value={datePickerValue(formData?.[field.key])}
                      onChange={(_, date) =>
                        onDatePickerOnChange({ target: { value: date } }, field)
                      }
                      placeholder="Choose a date"
                      aria-label="Select a date"
                      style={styles.DatePicker}
                    />
                    {errors?.[field.key] && (
                      <div className="mdm-validation">
                        {errors[field.key]}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </GridItem>
          ))}
        </Grid>
      </div>
      <Grid hasGutter style={{marginBottom: '15px', marginTop: '15px'}}>
        <GridItem span={12} key={'commitReason'} style={styles.GridItem}>
          <span className={`create-mdm-data__dpc-required-fields`} style={styles.GridItemDivSpan}>Commit Reason</span>
          <div style={styles.GridItemDiv}>
            <TextInput
              placeholder={`${isEdit ? 'Updating record' : 'Creating a new record'} `}
              value={formData?.['commitReason']}

              autoComplete="off"
              isRequired={true}
              type={'text'}
              id={'commitReason'}
              name={'commitReason'}
              onChange={(value: any) => handleChange(value, 'commitReason')}

              style={styles.commitReason}
              className="rectangle-input"
            />
            <br />

          </div>
          {errors?.['commitReason'] && (
            <div className="mdm-validation">
              {errors['commitReason']}
            </div>
          )}
        </GridItem>
      </Grid>


      <ActionGroup className="create-footer" style={styles.ActionGroup}>
        {
          !isLoading && (<>
            <Button
              variant="primary"
              onClick={handleSave}
              style={styles.ActionGroupPrimaryButton}
            >
              {isEdit ? "Commit" : "Create"}
            </Button>
            <Button
              variant="secondary"
              onClick={onClose}
              style={styles.ActionGroupSecondaryButton}
            >
              Cancel
            </Button>
          </>)
        }

        {
          isLoading && <div> Saving...<Spinner size="lg" aria-label="Saving..." /></div>
        }

      </ActionGroup>
    </DrawerPanelContent>
  );
};
export default EditCreateModal;
