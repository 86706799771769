import { createWithEqualityFn } from "zustand/traditional";

import { shallow } from "zustand/shallow";
import {  MDMTableDetailsFilter, MDMTableDetails, MDMTableFilter, MDMTableList, MDMHeaders, MDMHistoryList, MDMHistoryFilter } from "./Modals";
import { PaginationRequest, PaginationResult } from "../ProjectModels";

interface MDMTableState {
  isLoading: boolean;
  list: MDMTableList[];
  headers: MDMHeaders[],
  paginationRequest: PaginationRequest;
  paginationResult: PaginationResult;
  activeFilter: MDMTableFilter;
  setMDMTableList: (list: MDMTableList[]) => void;
  setPaginationRequest: (pagination: PaginationRequest) => void;
  setPaginationResult: (pagination: PaginationResult) => void;
  setActiveFilter: (filter: MDMTableFilter) => void;
}

export const useMDMTableStore = createWithEqualityFn<MDMTableState>(
  (set) => ({
    isLoading: false,
    list: [],
    headers: [],
    paginationRequest: PaginationRequest.default(),
    paginationResult: PaginationResult.empty(),
    activeFilter: MDMTableFilter.default(),
    setMDMTableList: (list: MDMTableList[]) => {
      set((state: MDMTableState) => ({
        list: list,
      }));
    },
    setLoading: (isLoading: boolean) => {
      set(() => ({
        isLoading: isLoading,
      }));
    },
    setMDMHeaders: (headers: MDMHeaders[]) => {
      set(() => ({
        headers: headers,
      }));
    },
    setPaginationRequest: (pagination: PaginationRequest) => {
      set((state: MDMTableState) => ({
        paginationRequest: pagination,
      }));
    },
    setPaginationResult: (pagination: PaginationResult) => {
      set((state: MDMTableState) => ({
        paginationResult: pagination,
      }));
    },
    setActiveFilter: (filter: MDMTableFilter) => {
      set((state: MDMTableState) => ({
        activeFilter: filter,
      }));
    },
  }),
  shallow
);


/**
 * @purpose MDM @details talbe interface
 */
interface MDMTableDetailsState {
  isLoading: boolean;
  list: MDMTableDetails[];
  headers: MDMHeaders[];
  paginationRequest: PaginationRequest;
  paginationResult: PaginationResult;
  activeFilter: MDMTableDetailsFilter;
  setMDMTableRecords: (mdmTableRecords: MDMTableDetails[]) => void;
  setPaginationRequest: (pagination: PaginationRequest) => void;
  setPaginationResult: (pagination: PaginationResult) => void;
  setActiveFilter: (filter: MDMTableDetailsFilter) => void;
}

/**
 * @purpose mdm @details @hooks for set state and fetch as common
 */
export const useMDMTableDetailsStore = createWithEqualityFn<MDMTableDetailsState>(
  (set) => ({
    isLoading: false,
    list: [],
    headers: [],
    paginationRequest: PaginationRequest.default(),
    paginationResult: PaginationResult.empty(),
    activeFilter: MDMTableDetailsFilter.default(),
    setMDMTableRecords: (list: MDMTableDetails[]) => {
      set(() => ({
        list: list,
      }));
    },
    setLoading: (isLoading: boolean) => {
      set(() => ({
        isLoading: isLoading,
      }));
    },
    setMDMTableHeaderRecords: (headers: MDMHeaders[]) => {
      set(() => ({
        headers: headers,
      }));
    },
    setPaginationRequest: (pagination: PaginationRequest) => {
      set(() => ({
        paginationRequest: pagination,
      }));
    },
    setPaginationResult: (pagination: PaginationResult) => {
      set(() => ({
        paginationResult: pagination,
      }));
    },
    setActiveFilter: (filter: MDMTableDetailsFilter) => {
      set(() => ({
        activeFilter: filter,
      }));
    },
  }),
  shallow
);


interface MDMHistoryState {
  isLoading: boolean;
  list: MDMHistoryList[];
  headers: MDMHeaders[],
  paginationRequest: PaginationRequest;
  paginationResult: PaginationResult;
  activeFilter: MDMHistoryFilter;
  setList: (list: MDMHistoryList[]) => void;
  setPaginationRequest: (pagination: PaginationRequest) => void;
  setPaginationResult: (pagination: PaginationResult) => void;
  setActiveFilter: (filter: MDMHistoryFilter) => void;
  setHeaders: (header: MDMHeaders[]) => void;
}

export const useViewHistoryMDMStore = createWithEqualityFn<MDMHistoryState>(
  (set) => ({
    isLoading: false,
    list: [],
    headers: [],
    paginationRequest: PaginationRequest.default(),
    paginationResult: PaginationResult.empty(),
    activeFilter: MDMHistoryFilter.default(),
    setList: (list: MDMHistoryList[]) => {
      set((_) => ({
        list: list,
      }));
    },
    setLoading: (isLoading: boolean) => {
      set(() => ({
        isLoading: isLoading,
      }));
    },
    setHeaders: (headers: MDMHeaders[]) => {
      set((_) => ({
        headers: headers,
      }));
    },
    setPaginationRequest: (pagination: PaginationRequest) => {
      set((_) => ({
        paginationRequest: pagination,
      }));
    },
    setPaginationResult: (pagination: PaginationResult) => {
      set((_) => ({
        paginationResult: pagination,
      }));
    },
    setActiveFilter: (filter: MDMHistoryFilter) => {
      set((_) => ({
        activeFilter: filter,
      }));
    },
  }),
  shallow
);