import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerContentBody,
} from "@patternfly/react-core";
import "../../designer/karavan.css";
import { PaginationRequest } from "../../api/ProjectModels";
import { useNavigate, useParams } from "react-router-dom";
import { useMDMTableDetailsStore } from "../../api/mdm/Store";
import { MDMTableDeatilsService } from "../../api/mdm/Service";
import {
  GenericType,
  MDMTableDetails,
  MDMTableDetailsFilter,
} from "../../api/mdm/Modals";
import { DeleteRecordModal } from "./DeleteModal";
import MdmTableToolbar from "./Toolbar";
import { EventBus } from "../../designer/utils/EventBus";
import EditCreateModal from "./EditCreateModal";
import DataTable from "../shared/data-table/DataTable";
type operations = "delete" | "edit" | "checkbox" | "selectAll" | "filters" | "multipleDelete";

interface TableParams {
  tableName: string;
}

export const MDMDetailsPage = () => {
  const { tableName }: Partial<TableParams> = useParams();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [primaryIds, setPrimaryIds] = useState<string | null | undefined | number | any[]>(null);
  const [isDeleteAll, setDeleteAll] = useState<boolean>(false);
  const [isExpands, setExpands] = useState(false);
  const [editObject, setEditObject] = useState(null);
  const [filters, setFilters] = useState(null);
  const {
    paginationRequest,
    setPaginationRequest,
    paginationResult,
    activeFilter,
  } = useMDMTableDetailsStore();

  const navigate = useNavigate();

  const getTableData = (
    pagination: PaginationRequest,
    filter: MDMTableDetailsFilter
  ) => {
    return MDMTableDeatilsService.getMDMDetailsTableRecords(pagination, filter);
  };

  useEffect(() => {
    const defaultFilter = MDMTableDetailsFilter.default();
    defaultFilter.tableName = tableName;
    useMDMTableDetailsStore.getState().setActiveFilter(defaultFilter);
    getTableData(PaginationRequest.default(), defaultFilter);
    return () => {
      useMDMTableDetailsStore.setState({ list: [] });
      useMDMTableDetailsStore.setState({
        paginationResult: {
          currentPage: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      });
      useMDMTableDetailsStore.setState({ headers: [] });
    };
  }, [tableName]);

  const onSetPage = (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPage: number
  ) => {
    const newPaginationRequest = new PaginationRequest(
      newPage,
      paginationRequest.size
    );

    setPaginationRequest(newPaginationRequest);
    return getTableData(newPaginationRequest, activeFilter);
  };

  const onPerPageSelect = (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPerPage: number,
    newPage: number
  ) => {
    const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

    setPaginationRequest(newPaginationRequest);
    return getTableData(newPaginationRequest, activeFilter);
  };

  const onSearch = ({ target: { value } }: any): void => {
    const newPaginationRequest = new PaginationRequest(
      1,
      paginationRequest.size
    );
    setPaginationRequest(newPaginationRequest);
    const filter = MDMTableDetailsFilter.default();
    filter.searchTerm = value;
    filter.tableName = tableName;
    useMDMTableDetailsStore.getState().setActiveFilter(filter);
    getTableData(newPaginationRequest, filter);
  };

  const primaryKey =  useMDMTableDetailsStore.getState().headers.find((col:any) => col?.primary)?.key || '';
  const onActionClick = (type: operations, propsValue: GenericType, event?:any) => {
    if (type === "delete") {
      setPrimaryIds(propsValue.value?.[primaryKey]);
      setDeleteModalOpen(true);
    } else if (type === "selectAll" && propsValue) {
      let mdmDetailsTableRecords: MDMTableDetails[] =
        useMDMTableDetailsStore.getState().list;
      if (propsValue.checked) {
        mdmDetailsTableRecords = mdmDetailsTableRecords.map((obj) => {
          obj.isChecked = true;
          return obj;
        });
      } else {
        mdmDetailsTableRecords = mdmDetailsTableRecords.map((obj) => {
          obj.isChecked = false;
          return obj;
        });
      }
      setDeleteAll(propsValue.isDeleteAll || false);
      useMDMTableDetailsStore.setState({
        list: mdmDetailsTableRecords || [],
      });
    } else if (type === "checkbox" && propsValue) {
      let mdmDetailsTableRecords: MDMTableDetails[] = JSON.parse(
        JSON.stringify(
          useMDMTableDetailsStore.getState().list
        )
      );
      let mdmTableIndex = mdmDetailsTableRecords.findIndex(
        (obj) => obj?.[primaryKey] === propsValue?.value?.[primaryKey]
      );
      mdmDetailsTableRecords[mdmTableIndex]["isChecked"] = event.target.checked;
      useMDMTableDetailsStore.setState({ list: [] });
      useMDMTableDetailsStore.setState({
        list: [...mdmDetailsTableRecords],
      });
      setDeleteAll(false);
    } else if (type === "edit" && propsValue) {
      setEditObject((prev) => propsValue.value);
      setExpands(true);
    } else if(type == 'multipleDelete'){
      if(isDeleteAll) {
        setPrimaryIds([]);
      } else{
        setPrimaryIds(useMDMTableDetailsStore.getState()?.list?.filter(row => row?.isChecked).map(row => row[primaryKey]));
      }
      setDeleteModalOpen(true);
    }
  };

  const deleteConfirm = () => {
    getTableData(paginationRequest, activeFilter);
  };

  const onToolBarAction = (type: string) => {
    if (type === "refresh") {
      const filter = MDMTableDetailsFilter.default();
      filter.tableName = tableName;
      useMDMTableDetailsStore.getState().setActiveFilter(filter);
      getTableData(paginationRequest, filter);
    } else if (type === "viewHistory") {
      navigate("/mdm/" + tableName + "/viewHistory");
    }  else if (type === "create") {
      setEditObject(null);
      setExpands(true);
    }
  };

  const handleSave = (obj: any, isEdit?: boolean) => {
    MDMTableDeatilsService.saveMdmData(tableName, obj, primaryKey, isEdit)
      .then((result) => {
        const {data : {code , message, success}} = result;
        if(code === 200 || code === 201 || success) {
          EventBus.sendAlert("Success", message, "success");
          getTableData(paginationRequest, activeFilter);
          setExpands(false);
          setEditObject(null);
        } else {
          setExpands(false);
          setEditObject(null);
          EventBus.sendAlert("Error", message, "danger");
        }
      })
      .catch((error) => {
       const { response : { data : { message }}} = error;
        setEditObject(null);
        setExpands(false);
        EventBus.sendAlert("Error", message || error?.message || `Opps!!, something wrong`, "danger");
      });
  };

  const panelContent = (
    <EditCreateModal
      fields={useMDMTableDetailsStore.getState().headers}
      onSave={handleSave}
      onCancel={() => {
        setExpands(false);
        setEditObject(null);
      }}
      editObject={editObject}
    />
  );

  const filterActions = (data:any, type: string) =>{
    const _filter = MDMTableDetailsFilter.default();
    if(type === 'cancelFilters') {
      _filter.filter = [];
    } else if(type === 'runFilters'){
      _filter.filter = data;
      setFilters(data);
    } else if(type === 'clearFilters') {
      _filter.filter = [];
      setFilters(null);
    }
    _filter.tableName = tableName;
    useMDMTableDetailsStore.getState().setActiveFilter(_filter);
    getTableData(paginationRequest, _filter);
  }

  const drowerDynamicClass = () =>{
    if(useMDMTableDetailsStore.getState().headers?.length >=10) {
     return 'drower-expanded'
    }
    return ''
  }
  return (
    <>
      <Drawer isExpanded={isExpands} position="right" className={drowerDynamicClass()}>
        <DrawerContent panelContent={panelContent} style={{ width: "50%" }}>
          <DrawerContentBody style={{backgroundColor: '#F0F0F0'}}>
            <MdmTableToolbar
              titleText={tableName}
              toolBarContent={{
                isRefresh: false,
                isUpload: false,
                isViewHistory: true,
                create: true,
              }}
              breadCrumbItems={[{title: 'Master Data Management', to: '/mdm'}, {title: tableName}]}
              onToolBarAction={onToolBarAction}
            />
            <DataTable
              headers={useMDMTableDetailsStore.getState().headers}
              itemCount={paginationResult?.totalItems}
              onActionClick={onActionClick}
              data={useMDMTableDetailsStore.getState().list}
              itemPerPage={paginationResult.itemsPerPage}
              pages={paginationRequest.page}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
              onSearch={onSearch}
              isExpands={isExpands}
              isLoading= {useMDMTableDetailsStore.getState().isLoading || false}
              tableName={tableName}
              filterActions={filterActions}
              filters={filters}
            />

            {isDeleteModalOpen && (
              <DeleteRecordModal
                isOpen={isDeleteModalOpen}
                onClose={setDeleteModalOpen}
                onConfirm={deleteConfirm}
                primaryIds={primaryIds}
                setPrimaryIds={setPrimaryIds}
                tableName={tableName}
                isDeleteAll={isDeleteAll}
                itemCount={paginationResult?.totalItems}
              />
            )}
          </DrawerContentBody>
        </DrawerContent>
      </Drawer>
      <div
        style={
          isExpands
            ? {
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: "100",
              }
            : {}
        }
      ></div>
    </>
  );
};
