import React, { useState } from "react";
import { Button, Grid, GridItem, Modal, ModalVariant, TextInput } from "@patternfly/react-core";
import "../../designer/karavan.css";
import { MDMTableDeatilsService } from "../../api/mdm/Service";
import { EventBus } from "../../designer/utils/EventBus";
export const DeleteRecordModal = ({
  isOpen,
  onClose,
  onConfirm,
  primaryIds,
  setPrimaryIds,
  tableName,
  isDeleteAll,
  itemCount,
}: any) => {
  const [commitReason, setCommitReason] = useState('');
  const isMultipleDelete = isDeleteAll || (primaryIds && Array.isArray(primaryIds) && primaryIds.length > 1) ? true : false;
  const recordText = isMultipleDelete ? 'records' : 'record';
  const closeModal = () => {
    setPrimaryIds && setPrimaryIds(null);
    onClose && onClose(false);
  };

  async function confirmAndCloseModal() {
    MDMTableDeatilsService.deleteMDMData(primaryIds, tableName, commitReason, isDeleteAll)
      .then((res:any) => {
        const { data: {code, message}} = res;
        if(code === 200 || code === 201){
          let msg = message;
          if(isMultipleDelete){
            msg = 'Records deleted successfully';
          }
          EventBus.sendAlert("Success", msg, "success");
          onConfirm();
          closeModal();
        }

      })
      .catch((err) => {
        EventBus.sendAlert(
          "Error",
          `${primaryIds} doesn't exist. Please try again`,
          "danger"
        );
      });
  }
  const onChange = (event:any) => {
   const { value } = event.target; 
   setCommitReason(value.trimStart());
  }
  return (
    <Modal
      title="Delete"
      variant={ModalVariant.small}
      isOpen={isOpen}
      onClose={closeModal}
      actions={[
        <div
          key={0}
          style={{ display: "flex", width: "100%" }}
        >
          <Button key="0" variant="primary" onClick={confirmAndCloseModal} isDisabled={commitReason === ''} style={{marginRight: '7px'}}>
            Delete
          </Button>
          <Button key="1" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>,
      ]}
      onEscapePress={onClose}
    >
      <div>
        <>{`Are you sure you want to delete ${isMultipleDelete ? `the selected ${isDeleteAll ? itemCount : primaryIds?.length}` : 'this'} ${recordText}? This action cannot be undone.`}</>
         <Grid hasGutter style={{marginTop: '15px'}}>
                <GridItem span={12} key={'commitReason'} >
                  <span className={`create-mdm-data__dpc-required-fields`}><b>Commit Reason</b></span>
                  <div >
                    <TextInput
                      value={commitReason}
                      placeholder={`Deleting the ${recordText}`}
                      style={{border: '1px solid grey', borderRadius: '8px', marginTop: '5px'}}
                      autoComplete="off"
                      isRequired={true}
                      type={'text'}
                      id={'commitReason'}
                      name={'commitReason'}
                      onChange={onChange}
                      className="rectangle-input"
                    />
                    <br />
        
                  </div>
                </GridItem>
              </Grid>
      </div>
    </Modal>
  );
};
