import {KaravanApi} from "../../api/KaravanApi";


export class SmhAuthenticationUtil {
    static isAuthenticatedUserViewerOnly() {
        const defaultSmhRoles = ["viewer", "developer", "administrator"];
        const userRoles = KaravanApi.me?.roles;
        const userDoesntContainAnyDefaultRole = defaultSmhRoles.filter((role) => userRoles.includes(role)).length === 0;

        return userDoesntContainAnyDefaultRole || (userRoles.includes("viewer") && !(userRoles.includes("administrator") || userRoles.includes("developer")));
    }
}