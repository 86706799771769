import { memo, useCallback, useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "@patternfly/react-table";
import {
  Bullseye,
  Button,
  EmptyState,
  EmptyStateIcon,
  EmptyStateVariant,
  Flex,
  FlexItem,
  PageSection,
  Panel,
  PanelFooter,
  PanelHeader,
  PanelMain,
  PanelMainBody,
  Spinner,
  TextInput,
  Title,
} from "@patternfly/react-core";
import { FilterIcon, SearchIcon,  TrashIcon } from "@patternfly/react-icons";
import { Pagination } from "@patternfly/react-core";
import "./DataTable.css";
import {
  GenericType,
  MDMHeaders,
} from "../../../api/mdm/Modals";
 import CustomDate from "../CustomDate";
import FilterComponent from "../filters/Filters";
import DataTableCheckboxDropdown from "./CheckboxDropdown";
import SaveFilter from "../filters/SaveFilter";
type operations = "delete" | "edit" | "checkbox" | "selectAll" | "filters" | 'multipleDelete' ;

interface DataTableProps {
  isLoading?: boolean;
  filters?: any;
  filterActions?: (data: any, type: string) => any;
  isFilterDispaly?: boolean;
  isSearchDisplay?: boolean;
  isDatePickerDisplay?:boolean;
  isPaginationDisplay?: boolean;
  isExpands?: boolean;
  headers?: MDMHeaders[];
  itemCount: number;
  data: GenericType[];
  tableName?: string;
  itemPerPage: number;
  tableWidth?: string;
  onActionClick?: (type: operations, obj: GenericType) => any;
  onSearch: (event: React.MouseEvent<HTMLInputElement>) => void;
  pages: number;
  onSetPage: (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent | any,
    newPage: number
  ) => Promise<void>;
  onPerPageSelect: (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPerPage: number,
    newPage: number
  ) => Promise<void>;
}

//Component to generate generic table
const DataTable = ({
  headers,
  onActionClick,
  itemCount,
  onSearch,
  data,
  itemPerPage,
  pages,
  onSetPage,
  onPerPageSelect,
  isLoading,
  tableWidth = '300px',
  isFilterDispaly = true,
  isSearchDisplay = true,
  isPaginationDisplay = true,
  isDatePickerDisplay = false,
  tableName,
  filterActions,
  filters,
}: DataTableProps) => {
  const [filteredData, setFilteredData] = useState<GenericType[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth - 150);
  const [isFilterOpen, setFilterOpen] = useState<any>(false);
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(!data?.length){
       setCheckedAll(false);
    } else {
      setCheckedAll(!data.find((obj) => !obj.isChecked));
    }
    setFilteredData(data);
  }, [data]);

  const getHeaderCells = () => {
    return headers?.filter((row: any) => !row?.isNotDisplay)?.map((column: any) => ({
      title: column.header,
    }));
  };

  const headerCells = getHeaderCells();

  const getSearchPlaceholder = () => {
    return (
      "Search by " +
      headerCells?.map((item: GenericType) => item.title).join(",")
    );
  };

  const onCheckedAll = (value:any) => {
    const isDeleteAll = value === 'all';
    const checked =  (value > 0 || isDeleteAll) ? true : false;
    setCheckedAll(checked || isDeleteAll);
    onActionClick && onActionClick("selectAll", { checked, isDeleteAll });
  };

  const columnDisplay = (row: any, mainIndex: number) => {
    const columnArray: any = [];
    headers?.filter((row: any) => !row?.isNotDisplay)?.map((column, index) => {
      if (
        column.component &&
        (column.key === "action" || column.key === "checkedAll")
      ) {
        columnArray.push(
          <Td key={index}>
            {column?.component({ value: row, onActionClick: onActionClick, index: mainIndex })}
          </Td>
        );
      } else if (column.component && column.key !== "action") {
        columnArray.push(
          <Td key={index}>
            {column?.component({
              value: row[column.key],
              onActionClick: onActionClick,
            })}
          </Td>
        );
      } else {
        columnArray.push(<Td key={index}>{row[column.key]}</Td>);
      }
    });
    return columnArray;
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      onSearch(e);
    }
  };

  const onInput = (e: any) => {
    if (!e.target.value) {
      onSearch(e);
    }
  };
 
  const openFilters = () => {
    setFilterOpen(true);
  }

  const FilterList = useCallback(()=>{
    if(!headers?.length) return <></>;
    const _headers = headers.filter(row => row.key !== 'action' &&  row.key !='checkedAll')?.map((row:any) =>{
       return {
        label: row?.header,
        content: row?.header,
        value: row?.key,
        type: row?.type || 'number'
       }
    }) || []
   return <FilterComponent tableName={tableName} fieldOptions={_headers} cb={filterActions} setFilterOpen={setFilterOpen} filters={filters}/>
  },[headers])

  return (
    <PageSection>
      <Panel isScrollable>
        {
          (isSearchDisplay || isDatePickerDisplay || isFilterDispaly ) && (<PanelHeader style={{ paddingBlockEnd: "0px", marginBottom: '8px' }}>
            <Flex
              className="toolbar"
              direction={{ default: "row" }}
            >
              {
                data && data?.length>0 && data.some((row:any) => row?.isChecked) && (<FlexItem><Button variant="secondary" icon={<TrashIcon />}  onClick={()=> onActionClick && onActionClick('multipleDelete', {})}>&nbsp;Delete</Button></FlexItem>)
              }
              
              <FlexItem align={{ default: "alignRight" }} className={`${isDatePickerDisplay ? 'cc-date-picker-container' : ''}`}>
                {
                  isSearchDisplay && (<TextInput
                    className="text-field"
                    type="search"
                    name="filter"
                    autoComplete="off"
                    placeholder={getSearchPlaceholder()}
                    id="search"
                    onKeyDown={handleKeyDown}
                    onInput={onInput}
  
                  />)
                }
                { isDatePickerDisplay && (<div><CustomDate /></div>) }
  
              </FlexItem>
              { isFilterDispaly && (<Button className={`${isFilterOpen ? 'active-filters-button' : ''}`} variant="plain" onClick={() => openFilters()}><FilterIcon style={{ marginRight: '10px', paddingTop: '3px' }} /> Filters</Button>) }
            </Flex>
          </PanelHeader>)
        }
        {  isFilterOpen && FilterList() }
        {
          filters?.length> 0 && !isFilterOpen && <SaveFilter filters={filters} headers={headers} cb={filterActions}/>
        }
        
        <PanelMain maxHeight={`calc(100vh - ${tableWidth})`} style={{ maxWidth: width + "px", overflow: "auto", maxHeight: `calc(100vh - ${tableWidth})` }}>
          <PanelMainBody style={{ overflowX: 'auto', maxWidth: '100%' }}>
            <Table
              aria-label="Dynamic Table"
              variant={"compact"}
              className={"table data-table__container"}
              style={{ borderTop: "1px solid gainsboro" }}
            >
              <Thead>
                <Tr className="data-table-header-th">
                  {headerCells?.map((headerCell: any, index: any) => {
                    if (headerCell.title == "checkedAll") {
                      return (
                        <Th key={index} style={{width: '10px', paddingLeft: '6px'}}>
                          <DataTableCheckboxDropdown  itemPerPage={itemPerPage} itemCount={itemCount} onSelect={onCheckedAll} checkedAll={checkedAll}/>
                        </Th>
                      );
                    }
                    return <Th key={index}>{headerCell.title}</Th>;
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {isLoading && (
                  <Tr>
                    <Td colSpan={6}>

                      <Bullseye>
                        <EmptyState variant={EmptyStateVariant.sm}>
                          <Spinner size="lg" aria-label="Loading content" />
                          <Title headingLevel="h2" size="lg">
                            Loading...
                          </Title>
                        </EmptyState>
                      </Bullseye>
                    </Td>
                  </Tr>
                )}
                {filteredData.map((row: any, index: any) => (
                  <Tr key={index}>{columnDisplay(row, index)}</Tr>
                ))}

                {!isLoading && filteredData.length === 0 && (
                  <Tr>
                    <Td colSpan={6}>
                      <Bullseye>
                        <EmptyState variant={EmptyStateVariant.sm}>
                          <EmptyStateIcon icon={SearchIcon} />
                          <Title headingLevel="h2" size="lg">
                            No results found
                          </Title>
                        </EmptyState>
                      </Bullseye>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </PanelMainBody>
        </PanelMain>
        {  isPaginationDisplay && (
          <PanelFooter>
            <Pagination
              itemCount={itemCount}
              perPage={itemPerPage}
              page={pages}
              onSetPage={onSetPage}
              onPerPageSelect={onPerPageSelect}
            />
          </PanelFooter>)
       }
      </Panel>
    </PageSection>
  );
};

export default memo(DataTable);
