import React, { useEffect, useState } from "react";

import { Button } from "@patternfly/react-core";
import { TrashIcon } from "@patternfly/react-icons";
import './filters.css';
import { EventBus } from "../../../designer/utils/EventBus";
import InfFilterComponent from "../filters/InfinteDropdown";
import {
  TypeaheadSelect,
} from "@patternfly/react-templates";
import { Table, TableVariant, Tbody, Td, Tr } from "@patternfly/react-table";
import { OperationOptions } from "./FilterOptions";
interface RowData {
  field: string | null;
  operator: string | null;
  value: string | null;
  loading: boolean;
  logicalOperator: string | null;
  isChild: boolean;
}
const FilterComponent = ({ tableName, fieldOptions, setFilterOpen, cb, filters }: any) => {
  const [tableData, setTableData] = useState<RowData[]>([{ field: null, operator: null, value: null, loading: false, logicalOperator: '', isChild: false }]);
  useEffect(()=>{
     if(filters && filters?.length){
      setTableData(filters)
     }
  }, [filters])
  const onChangeFieldsValue = (field: string | null): any[] => {
    if (!field) return [];
    const type = fieldOptions?.find((row: any) => row.value === field)?.type;
    if (type) {
      return OperationOptions?.[type] ? OperationOptions[type] : OperationOptions['text'];
    }
    return OperationOptions['text'];
  }

  const deleteRow = (index: number) => {
    if (tableData.length === 1) return;
    const updatedData: any = tableData?.filter((_, i) => i !== index);
    if (updatedData[index - 1]?.['isChild']) {
      updatedData[index - 1]['isChild'] = false;
    }
    setTableData(updatedData);
  };

  const runFilter = () => {
    let updatedData = [...tableData];
    updatedData = updatedData.map((row: any) => {
      if ('loading' in row) {
        delete row.loading
      }
      return row;
    })
    setFilterOpen(false);
    cb(updatedData, 'runFilters')
  }

  const cancelFilters = () => {
    cb(null, 'cancelFilters')
    setTableData([{ field: null, operator: null, value: null, loading: false, logicalOperator: '', isChild: false }]);
    setFilterOpen(false);
  }

  const filterActions = (logicalOperator: string, index: number) => {
    const updatedData = [...tableData];
    if (updatedData.length - 1 !== index) {
      updatedData[index + 1]['logicalOperator'] = logicalOperator;
      setTableData(updatedData);
      return;
    }
    if (updatedData.length === 0 || updatedData.every(row => row.field && row.operator && row.value)) {
      if (updatedData?.[index]?.['isChild']) return;
      updatedData[index]['isChild'] = true;
      updatedData.push({ field: null, operator: null, value: null, loading: false, logicalOperator: logicalOperator, isChild: false });
      setTableData(updatedData);
    } else {
      EventBus.sendAlert("Validation", 'Before adding a new row, please finish the existing ones.', 'warning');
    };
  }

  const onSelect = (value: any, colKey: string, index: number) => {
    const updatedData: any[] = [...tableData];
    updatedData[index].value = value;
    setTableData(updatedData);
  }
  const ActionButtons = ({ index }: any) => {
    return (
      <div className="filters__action-buttons">
        <Button
          variant="link"
          className={`filters__button ${tableData?.[index + 1]?.['logicalOperator'] === 'AND' ? 'filter-custom-active-button' : ''}`}
          onClick={() => filterActions("AND", index)}
          title="Button"
          key={0}
        >
          AND
        </Button>

        <Button
          variant="link"
          className={`filters__button ${tableData?.[index + 1]?.['logicalOperator'] === 'OR' ? 'filter-custom-active-button' : ''}`}
          onClick={() => filterActions("OR", index)}
          key={1}
        >
          OR
        </Button>
        <Button
          variant="plain"
          className="filters__button pf-global--Color--gray-100"
          onClick={() => deleteRow(index)}
          key={2}
        >
          <TrashIcon />
        </Button>
      </div>
    );
  };
  return (
    <>
   
      <Table variant={TableVariant.compact}  gridBreakPoint="" style={{
        maxHeight: "100px",
        overflowY: "auto",
         background: '#F4F9FF'
      }}>
        <Tbody>
          {tableData.map((row, index) => (
            <Tr key={index}  style={{ borderBottom: 'none' , height: '15px', paddingTop: '0px', paddingBottom: '0px' }} >
              <Td className="filter-table-cols filters-select-typehaed" key={`${index}-td-01`} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <TypeaheadSelect
                  placeholder="Select field"
                  className="filters-select-typehaed-1 filters-select-typehaed-infinite-scrollbar"
                  selectOptions={fieldOptions}
                  noOptionsFoundMessage={() => `No record found`}
                  onClearSelection={() => {
                    const updatedData: any[] = [...tableData];
                    updatedData[index].field = '';
                    updatedData[index].operator = '';
                    updatedData[index].value = '';
                    setTableData(updatedData);
                  }}
                  onSelect={(_ev, selectedValue) => {
                    const updatedData: any[] = [...tableData];
                    updatedData[index].field = selectedValue || '';
                    setTableData(updatedData);
                  }}
                  selected={tableData[index].field}
                  style={{  background: 'white', borderRadius: '15px' }}  />
              </Td>
              <Td className="filter-table-cols" style={{ paddingTop: '0px', paddingBottom: '0px' }} key={`${index}-td-02`}>
                <TypeaheadSelect
                  placeholder="Select operation"
                  isDisabled={!tableData[index]?.field}
                  selectOptions={onChangeFieldsValue(row.field)}
                  noOptionsFoundMessage={() => `No record found`}
                  onClearSelection={() => {
                    const updatedData: any[] = [...tableData];
                    updatedData[index].operator = '';
                    setTableData(updatedData);
                  }}
                  onSelect={(_ev, selectedValue) => {
                    const updatedData: any[] = [...tableData];
                    updatedData[index].operator = selectedValue || '';
                    setTableData(updatedData);
                  }}
                  selected={tableData[index].operator}
                  style={{  background: 'white', borderRadius: '15px', borderBottom: 'none' }} />
              </Td>
              <Td className="filter-table-cols filter-table-infinte-td" style={{ paddingTop: '0px', paddingBottom: '0px',  width: '23vw' }} key={`${index}-td-03`}>
                {
                  !tableData[index]?.field && (<TypeaheadSelect
                     placeholder="Enter value"
                    selectOptions={[]}
                    isDisabled={!tableData[index]?.field}
                     />)
                }

                {
                  tableData[index]?.field && (<InfFilterComponent  tableName={tableName} colKey={tableData[index].field} index={index} onSelect={onSelect} selectedValue={tableData[index].value} />
                  )
                }
              </Td>
              <Td  key={`${index}-td-04`} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <ActionButtons index={index} />
              </Td>
            </Tr>
          ))}
        </Tbody>

      </Table>
   
      <Table style={{ background: '#F4F9FF' }}>
       <Tbody>
        <Tr>
          <Td colSpan={100} style={{ borderBottom: 'none', float: 'right' }}>
            <Button variant="plain" onClick={cancelFilters}>Cancel</Button>
            <Button onClick={runFilter} isDisabled={!tableData?.every((row: any) => row.field && row.operator && row.value)}>Apply Filter</Button>
          </Td>
        </Tr>
        </Tbody>
      </Table>

    </>
  );
};

export default FilterComponent;