import React, { useEffect } from "react";
import "../../designer/karavan.css";
import { PaginationRequest } from "../../api/ProjectModels";
import { useMDMTableStore } from "../../api/mdm/Store";
import { MDMTableFilter } from "../../api/mdm/Modals";
import { MDMTableService } from "../../api/mdm/Service";
import MdmTableToolbar from "./Toolbar";
import DataTable from "../shared/data-table/DataTable";

export const MDMHome = () => {
  const {
    paginationRequest,
    setPaginationRequest,
    paginationResult,
    activeFilter,
  } = useMDMTableStore();

  useEffect(() => {
    const defaultFilter = MDMTableFilter.default();
    useMDMTableStore.getState().setActiveFilter(defaultFilter);
    getTableData(PaginationRequest.default(), defaultFilter);
  }, []);

  const getTableData = (
    pagination: PaginationRequest,
    filter: MDMTableFilter
  ) => {
    return MDMTableService.getMDMTableList(pagination, filter);
  };

  const onSetPage = (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPage: number
  ) => {
    const newPaginationRequest = new PaginationRequest(
      newPage,
      paginationRequest.size
    );

    setPaginationRequest(newPaginationRequest);
    return getTableData(newPaginationRequest, activeFilter);
  };

  const onPerPageSelect = (
    _event: React.MouseEvent | React.KeyboardEvent | MouseEvent,
    newPerPage: number,
    newPage: number
  ) => {
    const newPaginationRequest = new PaginationRequest(newPage, newPerPage);

    setPaginationRequest(newPaginationRequest);
    return getTableData(newPaginationRequest, activeFilter);
  };

  const onSearch = ({ target: { value } }: any): any => {
    const newPaginationRequest = new PaginationRequest(
      1,
      paginationRequest.size
    );
    setPaginationRequest(newPaginationRequest);
    const filter = MDMTableFilter.default();
    filter.searchTerm = value;
    MDMTableService.getMDMTableList(newPaginationRequest, filter);
    useMDMTableStore.getState().setActiveFilter(filter);
  };

  const onToolBarAction = (type: string) => {
    if (type === "refresh") {
      getTableData(paginationRequest, activeFilter);
    }
  };

  return (
    <>
      <MdmTableToolbar
        titleText={"Master Data Management"}
        toolBarContent={{ isRefresh: false }}
        onToolBarAction={onToolBarAction}
      />
      <DataTable
        headers={useMDMTableStore.getState().headers || []}
        itemCount={paginationResult?.totalItems}
        data={useMDMTableStore.getState().list || []}
        tableName="masterDataView"
        itemPerPage={paginationResult.itemsPerPage}
        pages={paginationRequest.page}
        onSetPage={onSetPage}
        onPerPageSelect={onPerPageSelect}
        onSearch={onSearch}
        isLoading= {useMDMTableStore.getState().isLoading || false}
        isFilterDispaly={false}
      />
    </>
  );
};
